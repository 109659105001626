import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Inspector } from 'react-dev-inspector'
// eslint-disable-next-line import/no-extraneous-dependencies
import launchEditorEndpoint from 'react-dev-utils/launchEditorEndpoint'
import axios from 'axios'

export const InspectorWrapper = process.env.NODE_ENV === 'development'
  ? Inspector
  : React.Fragment

export const getDebugSource = (fiber, result=[]) => {
  if(fiber._debugSource){
    result.push(fiber._debugSource)
  }
  if(fiber._debugOwner && result.length < 11){
    return getDebugSource(fiber._debugOwner,result)
  }
  return result
}
export const gotoEditor = (source) => {
  if (!source) return

  const {
    lineNumber,
    columnNumber,
    relativePath,
    absolutePath,
  } = source

  const isRelative = Boolean(relativePath)

  const launchParams = {
    fileName: isRelative ? relativePath : absolutePath,
    lineNumber,
    colNumber: columnNumber,
  }

  /**
   * api in 'react-dev-inspector/plugins/webpack/middlewares' launchEditorMiddleware
   */
  const apiRoute = isRelative
    ? `${launchEditorEndpoint}/relative`
    : launchEditorEndpoint
  axios.get(`${apiRoute}`,{params:launchParams})
}
