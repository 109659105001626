import * as React from 'react'
import moment from 'moment'
import { DatePicker as AnDatePicker } from 'antd'
import fieldProps from './fieldProps'

const dateFormat = 'DD/MM/YYYY'
// const monthFormat = 'YYYY/MM';

class Datepicker extends React.Component {
  render() {
    const { onChange, format = 'YYYY-MM-DD', value, disabled } = this.props
    return (
      <AnDatePicker
        onChange={(m) => onChange(m ? m.format(format) : m)}
        value={value ? moment(value) : null}
        format={dateFormat}
        disabled={disabled}
      />
    )
  }
}
export default fieldProps(Datepicker)
