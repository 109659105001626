import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import DragUploader from 'components/platform/form/inputs/DragUploader'
import withNavigator from 'components/platform/hocs/withNavigator'
import FilePreview from 'components/platform/File/FilePreview'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'

const { TabPane } = Tabs
class DocumentForm extends React.Component {
  render() {
    const { form } = this.props
    console.log('this.props', this.props)
    const isUpdate = form.values.id > 0

    if (!isUpdate) {
      return (
        <div>
          <Field
            name={'file'}
            label={null}
            params={form.values}
            onSuccess={response => {
              this.props.modal.onClose()
              // TEMPORANEOOOO
              this.props.modal.modals.open('documents', {
                itemId: response.id,
                onClose: this.props.modal.onRequestClose,
              })
            }}
            component={DragUploader}
          />
        </div>
      )
    }

    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'documents.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.details'} />
            </b>
            <Field label="global.title" obb name="title" />
            <Field
              label="global.status"
              name="documentStatus"
              type={'document_status'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.type"
              name="documentType"
              type={'document_type'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.category"
              name="documentCategory"
              type={'document_category'}
              component={ItemCodeSelect}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
        {this.props.form.values.contentUrl && (
          <TabPane tab={'Preview'} key={'documents.preview'}>
            <FilePreview
              src={this.props.form.values.contentUrl.replace("/uploads/", "/uploads/cloud/")}
            />
          </TabPane>
        )}
      </Tabs>
    )
  }
}

export default withNavigator(
  withModalForm({
    apiResource: '/api/documents',
    title: <FormattedMessage id={'global.edit_document'} />,
    width: 1200,
  })(DocumentForm),
)
