  import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import InputNumber from 'components/platform/form/inputs/InputNumber'
import JAutocomplete from 'components/platform/form/inputs/JAutocomplete'
import AsyncSelect from 'components/platform/form/inputs/AsyncSelect'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'

const { TabPane } = Tabs

class ProductForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'products.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.name" obb name="name" />
            <Field label="global.part_number" name="partNumber" />
            <Field label="global.vendor_part_number" name="vendorPartNumber" />
            <Field label="global.product_sheet" name="productSheet" />
            <Field label="global.website" name="website" />
            <Field label="global.serial_no" name="serialNo" />
            <Field label="global.default_price" name="price" component={InputNumber} />
            <Field
              label="global.currency"
              name="currency"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/currencies'}
              component={AsyncSelect}
            />
            <Field
              label="global.vendor_name"
              name="vendor"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/vendors'}
              component={JAutocomplete}
              filter={'name'}
            />
            <Field
              label="global.type"
              name="productType"
              type={'product_type'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.category"
              name="productCategory"
              type={'product_category'}
              component={ItemCodeSelect}
            />
            <Field
              className="span2 mb-3"
              label={'global.image'}
              name="imagePath"
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/products',
  title: <FormattedMessage id={'global.edit_product_service'} />,
  width: 1200,
})(ProductForm)
