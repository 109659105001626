import React,{createContext, useContext, useEffect, useState} from "react";


type PreloaderStore = {
  [key : string]: any;
};

export const PreloaderContext = createContext<PreloaderStore | null>(null);


export const usePreloaderContext = () => {
  return useContext(PreloaderContext);
}

export interface PreloaderInterface {
  getData: () => Promise<any>;
}

type PreloaderProps = {
  children: any;
  preloader: PreloaderInterface
}

export const PreloaderProvider = ({children, preloader}: PreloaderProps) => {
  const [data, setData] = useState<PreloaderStore | null>(null);

  useEffect(() => {
    preloader.getData().then((r:any)=>{
      setData(r)
    })
  }, []);


  if(!data) return (
    <div className='text-center mt-2'>
      loading...
    </div>
  )

  return (
    <PreloaderContext.Provider value={data}>
      {children}
    </PreloaderContext.Provider>
  )
}
