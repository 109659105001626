import React from 'react'
import { Table as AntTable } from 'antd'
import apiClient from 'services/axios'

const defaultLength = 20

function showTotal(total, range) {
  return range[1] === total ? `${total} risultati` : `${range[0]}-${range[1]} di ${total} risultati`
}

export default class Table extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        pageSize: defaultLength,
        page: 0,
      },
      sorter: props.sorter || {},
      loading: false,
    }
  }

  componentDidMount() {
    const {sorter} = this.state
    this.fetchData({
      order: sorter.field ? {
        [sorter.field] : sorter.order === 'descend' || sorter.order === 'desc' ? 'desc' : 'asc'
      } : undefined,
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        pagination,
        sorter,
        loading: true,
      },
      () => {
        this.fetchData({
          itemsPerPage: pagination.pageSize,
          page: pagination.current,
          order: sorter.field ? {
            [sorter.field] : sorter.order === 'descend' || sorter.order === 'desc' ? 'desc' : 'asc'
          } : undefined,
          // sortField: sorter.field,
          // sortOrder: sorter.order,
          // ...filters,
        })
      },
    )
    // console.log("handleTableChange",{pagination2, filters, sorter})
  }

  getData = (params) => {
    const { parms, source } = this.props
    if(typeof source === 'function'){
      return source({
        params:{
          itemsPerPage: defaultLength,
          ...params,
          ...parms,
        }})
    }
    return apiClient.get(source, {
        params: {
          itemsPerPage: defaultLength,
          ...params,
          ...parms,
        }
      })
  }

  fetchData = (params = {}) => {
    const { onDataFetch } = this.props
    const { pagination } = this.state
       this.getData(params)
      .then(res => {
        const { data, total } = res.data
        if (onDataFetch) {
          onDataFetch(res.data)
        }
        this.setState({
          loading: false,
          data: res.data['hydra:member'] || data,
          pagination: {
            ...pagination,
            total: res.data['hydra:totalItems'] || total
          },
        })
      })
  }

  fetch() {
    this.refresh()
  }

  refresh() {
    const { pagination, sorter } = this.state
    this.setState({ loading: true }, () => {
      this.fetchData({
        itemsPerPage: pagination.pageSize,
        page: pagination.current,
        order: sorter.field ? {
          [sorter.field] : sorter.order === 'descend' || sorter.order === 'desc'? 'desc' : 'asc'
        } : undefined,
        // sortField: sorter.field,
        // sortOrder: sorter.order,
      })
    })
  }

  render() {
    // useFixedHeader
    const { columns, rowSelection, debug, onRow, size = 'middle', scroll = { x: true }, rowClassName, showHeader, pagination : tablePagination = true  } = this.props
    const { pagination, data, loading } = this.state
    /*
    expandedRowRender={}
    */

    const others = {}
    if(debug){
      others.expandedRowRender = record => (
        <pre style={{ margin: 0 }}>{JSON.stringify(record, null, 2)}</pre>
      )
    }

    return (
      <AntTable
        rowSelection={rowSelection}
        columns={columns}
        rowKey={record => record.id}
        dataSource={data}
        pagination={tablePagination ? { ...pagination, showTotal } : false}
        loading={loading}
        scroll={scroll}
        size={size}
        onRow={onRow}
        showHeader={showHeader}
        rowClassName={rowClassName}
        onChange={this.handleTableChange}
        {...others}
      />
    )
  }
}
