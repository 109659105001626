import React from 'react'

export default class InlineLayout extends React.Component {

  render() {
    const { content, sidebar} = this.props
    return (
      <div>
        {sidebar && (
          <div>
            {sidebar && sidebar}
          </div>
        )}
        <div style={{ minHeight: 'auto' }}>{content}</div>
      </div>
    )
  }
}
