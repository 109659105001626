import React, {useEffect, useMemo, useState} from "react";
import {Skeleton} from "antd";
import apiClient from "../../../../services/axios";
import {BASE_URL} from "../../../../services/jira/jiraClient";


export const IssuePreview = ({issueKey: key}: any) => {

  const [issue, setIssue] = useState<any>(null)

  useEffect(() => {
    apiClient.get(`${BASE_URL}/api/jira/issues/${key}`).then((res: any) => {
      setIssue(res.data)
    })
  }, [key])

  if(!issue) {
    return <Skeleton active />
  }

  return (
    <DescriptionPreview issue={issue} />
  )
}

export const DescriptionPreview = ({issue}: any) => {

  const description =  useMemo(() => {
    let des = issue.renderedFields?.description
    // attach by filenaname
    // let attachments = issue.renderedFields?.attachment
    const domain = (new URL(issue.self)).hostname;
    const images  = des.match(/(\/secure\/attachment\/[^ ]*)/ig)

    if(!images) {
      return des
    }
    // console.log('images', images, domain)
    images.forEach((image: any) => {
      console.log(image, `https://${domain}${image}`)
      des = des.replace(image, `https://${domain}${image}`) // attch.thumbnail
    })
    /* attachments.forEach((attch: any) => {
      images.forEach((image: any) => {
        if(image.indexOf(attch.filename) > -1) {
          des = des.replace(image, `"${attch.content}"`) // attch.thumbnail
        }
      })

    }) */

    // remove all width and height attributes
    return des.replaceAll(/(width|height)="[^"]*"/g, '')
  },[issue.renderedFields?.description,issue.self])

  return (
    <div className={"prose p-0"}>
      <div dangerouslySetInnerHTML={{__html: description}}  />
    </div>
  )
}
