import React from 'react'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import { Tabs } from 'antd'
import { FormattedMessage } from 'react-intl'
import Switch from 'components/platform/form/inputs/Switch'
import AsyncSelect from "../../../components/platform/form/inputs/AsyncSelect";

const { TabPane } = Tabs

class UserForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'users.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className="span2 mt-2">
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.first_name" obb name="firstName" />
            <Field label="global.last_name" obb name="lastName" />
            <Field
              label="global.language"
              obb
              name="language"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/languages'}
              component={AsyncSelect}
            />
            <Field label="global.is_active" name="isActive" component={Switch} />
            <Field label="global.email" obb name="email" />
            <Field label="global.secondary_email" name="secondaryEmail" />
            <Field label="global.department" name="department" />
            <Field label="global.title" name="title" />
            <Field label="global.office_phone" name="officePhone" />
            <Field label="global.mobile_phone" name="mobilePhone" />
            <Field label="global.home_phone" name="homePhone" />
            <Field label="global.signature" name="signature" />
            <Field label="Fax" name="fax" />
            <Field label="global.profile_image_path" name="profileImagePath" />
            <Field
              label="global.role"
              name="rolesUser"
              component={AsyncSelect}
              source={'/api/roles'}
              valueLabel={'displayName'}
              valueKey={'@id'}
              mode={"multiple"}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.address_information'} />
            </b>
            <Field label="global.street" name="addressStreet" />
            <Field label="global.city" name="addressCity" />
            <Field label="global.country" name="addressCountry" />
            <Field label="global.state" name="addressState" />
            <Field label="global.zip_code" name="addressPostalCode" />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.credentials'} />
            </b>
            <Field label="global.username" obb name="username" />
            <Field
              className="mb-2"
              label="global.password"
              autocomplete={'new-password'}
              type="password"
              name="plainPassword"
            />
            <div />
            <Field
              className="mb-3"
              label={''}
              append={<FormattedMessage id={'global.password_temporanea'} />}
              name="forcePasswordReset"
              component={Switch}
            />
          </div>
        </TabPane>
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: 'api/users',
  title: <FormattedMessage id={'global.edit_user'} />,
  width: 1200,
})(UserForm)
