import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { renderDate } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.recipients'} />,
    dataIndex: 'recipient',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.subject'} />,
    dataIndex: 'subject',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.sent_at'} />,
    dataIndex: 'sentAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
]

const EmailsField = ({ account, contact, leads, invoice, email }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Email'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        Emails
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_email'} />}
            onClick={() => {
              modals.open(MODAL_NAME.email, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: {
                  contact,
                  leads,
                  account,
                  invoice,
                  recipient: email,
                  emailFrom: 'hola@patento.it',
                },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/emails'}
    modalKey={MODAL_NAME.email}
    filters={{ account, contact, leads, invoice }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default EmailsField
