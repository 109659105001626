import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import JAutocomplete from 'components/platform/form/inputs/JAutocomplete'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import AsyncSelect from 'components/platform/form/inputs/AsyncSelect'
import InputNumber from 'components/platform/form/inputs/InputNumber'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'

const { TabPane } = Tabs

class OrderForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'orders.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.order_number" obb name="orderNumber" />
            <Field label="global.carrier_number" name="carrierNumber" />
            <Field
              label="global.deal"
              name="deal"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/deals'}
              component={JAutocomplete}
              getLabel={i => `${i.name}`}
              filter={'name'}
            />
            <Field label="global.customer_no" name="customerNo" />
            <Field
              label="global.contact"
              name="contact"
              useIris
              valueKey={'@id'}
              valueLabel={'fullName'}
              source={'/api/contacts'}
              component={JAutocomplete}
              getLabel={i => `${i.fullName}`}
              filter={'fullName'}
            />
            <Field
              label="global.account"
              name="account"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/accounts'}
              component={JAutocomplete}
              getLabel={i => `${i.name}`}
              filter={'name'}
            />
            <Field label="global.purchase_order" name="purchaseOrder" />
            <Field label="global.due_date" name="dueDate" component={Datepicker} />
            <Field label="global.order_date" name="orderDate" component={Datepicker} />
            <Field
              label="global.status"
              name="orderStatus"
              type={'order_status'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.carrier"
              name="orderCarrier"
              type={'order_carrier'}
              component={ItemCodeSelect}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.bill_address'} />
            </b>
            <Field label="global.bill_to" name="billTo" />
            <Field label="global.bill_tax_number" name="billTaxNumber" />
            <Field label="global.street" name="billStreet" />
            <Field label="global.city" name="billCity" />
            <Field label="global.zip_code" name="billZipCode" />
            <Field label="global.state" name="billState" />
            <Field label="global.country" name="billCountry" />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.shipping_address'} />
            </b>
            <Field label="global.ship_to" name="shipTo" />
            <Field label="global.ship_tax_number" name="shipTaxNumber" />
            <Field label="global.ship_street" name="shipStreet" />
            <Field label="global.ship_city" name="shipCity" />
            <Field label="global.ship_zip_code" name="shipZipCode" />
            <Field label="global.ship_state" name="shipState" />
            <Field label="global.ship_country" name="shipCountry" />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.tax_currency'} />
            </b>
            <Field
              label="global.tax"
              name="tax"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/taxes'}
              component={AsyncSelect}
            />
            <Field
              label="global.currency"
              name="currency"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/currencies'}
              component={AsyncSelect}
            />
            <Field label="global.delivery_cost" name="deliveryCost" component={InputNumber} />
            <Field label="global.discount" name="discount" component={InputNumber} />
            <Field label="global.paid" name="paid" component={InputNumber} />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.terms_condition'} />
            </b>
            <Field
              className={'span2'}
              label={'global.terms_condition'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="termsAndCond"
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/orders',
  title: <FormattedMessage id={'global.edit_order'} />,
  width: 1200,
})(OrderForm)
