import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import JAutocomplete from 'components/platform/form/inputs/JAutocomplete'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'
import DocumentsField from '../DocumentsPage/DocumentsField'

const { TabPane } = Tabs

class ServiceContractForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'services.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.name" obb name="name" className={"span2"} />
            <Field label="global.start_date" name="startDate" component={Datepicker} />
            <Field label="global.due_date" name="dueDate" component={Datepicker} />
            <Field
              label="global.priority"
              name="serviceContractPriority"
              type={'service_contract_priority'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.status"
              name="serviceContractStatus"
              type={'service_contract_status'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.account"
              name="account"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/accounts'}
              component={JAutocomplete}
              getLabel={i => `${i.name}`}
              filter={'name'}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 2 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
        {this.props.form.values['@id'] && (
          <TabPane
            tab={<FormattedMessage id={'global.documents'} />}
            key={'servicesContracts.documents'}
          >
            <DocumentsField servicesContract={this.props.form.values['@id']} />
          </TabPane>
        )}
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/service_contracts',
  title: <FormattedMessage id={'global.edit_service_contract'} />,
  width: 1200,
})(ServiceContractForm)
