import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { printName, renderDate } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.name'} />,
    dataIndex: 'name',
    sorter: true,
    width: 250,
  },
  {
    title: <FormattedMessage id={'global.next_step'} />,
    dataIndex: 'nextStep',
    sorter: true,
    width: 300,
  },
  {
    title: <FormattedMessage id={'global.probability'} />,
    dataIndex: 'probability',
    sorter: true,
    className: 'text-center',
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.expected_revenue'} />,
    dataIndex: 'expectedRevenue',
    sorter: true,
    className: 'text-center',
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.amount'} />,
    dataIndex: 'amount',
    sorter: true,
    className: 'text-center',
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.closing_date'} />,
    dataIndex: 'closingDate',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.business_type'} />,
    dataIndex: 'dealBusinessType',
    sorter: true,
    width: 160,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.stage'} />,
    dataIndex: 'dealStage',
    sorter: true,
    width: 160,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const DealsField = ({ contact, campaign, account }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Deals'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.deals'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_deal'} />}
            onClick={() => {
              modals.open(MODAL_NAME.deal, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: {
                  contacts: contact ? [contact] : undefined,
                  campaigns: campaign ? [campaign] : undefined,
                  account,
                },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/deals'}
    modalKey={MODAL_NAME.deal}
    filters={{ contacts: contact, campaigns: campaign, account }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default DealsField
