import React from 'react' // , {useEffect, useState}
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'
import InputNumber from 'components/platform/form/inputs/InputNumber'
import { FormattedMessage } from 'react-intl'
import {connect} from "react-redux"
import ContactsField from '../ContactsPage/ContactsField'
import TicketsField from '../TicketsPage/TicketsField'
import DealsField from '../DealsPage/DealsField'
import QuotesField from '../QuotesPage/QuotesField'
import OrdersField from '../OrdersPage/OrdersField'
import InvoicesField from '../InvoicesPage/InvoicesField'
import CampaignsField from '../CampaignsPage/CampaingsField'
import ServiceContractsField from '../ServiceContractsPage/ServiceContractsField'
import DocumentsField from '../DocumentsPage/DocumentsField'
import EventsField from '../CalendarPage/EventsField'
import EmailsField from '../EmailsPage/EmailsField'
import TasksField from "../TasksPage/TasksField";
import { SettableTabs } from '../../../components/platform/Tabs/SettableTabs'
import tabDefinitions from './tabsDefinitions.json'

const { TabPane  } = SettableTabs


class AccountForm extends React.Component {
  render() {
    return (
      <SettableTabs
        definitions={tabDefinitions}
        settingsName="accountTabs"
        tabPosition="left"
      >
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'accounts.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.account_name" obb name="name" />
            <Field label="global.tax_number" name="taxNumber" />
            <Field label="global.website" name="website" />
            <Field label="global.account_number" name="accountNumber" />
            <Field label="global.annual_revenue" component={InputNumber} name="annualRevenue" />
            <Field label="global.employees" component={InputNumber} name="employees" />
            <Field
              label="global.type"
              name="accountType"
              type={'account_type'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.industry"
              name="accountIndustry"
              type={'account_industry'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.rating"
              name="accountRating"
              type={'account_rating'}
              component={ItemCodeSelect}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.contact_data'} />
            </b>
            <Field label="global.phone" name="phone" />
            <Field label="global.email" obb name="email" />
            <Field label="global.secondary_email" name="secondaryEmail" />
            <Field label="Fax" name="fax" />
            <Field label="Skype ID" name="skypeId" />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.address_information'} />
            </b>
            <Field label="global.street" name="street" />
            <Field label="global.city" name="city" />
            <Field label="global.zip_code" name="zipCode" />
            <Field label="global.state" name="state" />
            <Field label="global.country" name="country" />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.events'} />} key={'accounts.events'}>
            <EventsField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.contacts'} />} key={'accounts.contacts'}>
            <ContactsField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.tasks'} />} key={'contacts.tasks'}>
            <TasksField account={this.props.form.values.account} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.tickets'} />} key={'accounts.tickets'}>
            <TicketsField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.deals'} />} key={'accounts.deals'}>
            <DealsField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.quotes'} />} key={'accounts.quotes'}>
            <QuotesField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.orders'} />} key={'accounts.orders'}>
            <OrdersField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {(this.props.hasInvoices && this.props.form.values['@id']) && (
          <TabPane tab={<FormattedMessage id={'global.invoices'} />} key={'accounts.invoices'}>
            <InvoicesField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.campaigns'} />} key={'accounts.campaigns'}>
            <CampaignsField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane
            tab={<FormattedMessage id={'global.service_contract'} />}
            key={'accounts.service_contract'}
          >
            <ServiceContractsField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.documents'} />} key={'accounts.documents'}>
            <DocumentsField account={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'Emails'} />} key={'accounts.emails'}>
            <EmailsField
              account={this.props.form.values['@id']}
              email={this.props.form.values.email}
            />
          </TabPane>
        )}
      </SettableTabs>
    )
  }
}

const mapStateToProps = ({user}) => ({
  hasInvoices: user.features.invoices
})

export default withModalForm({
  apiResource: '/api/accounts',
  title: <FormattedMessage id={'global.edit_account'} />,
  width: 1200,
})(connect(mapStateToProps)(AccountForm))
