import * as React from 'react'
import { Select as ASelect } from 'antd'
import fieldProps from './fieldProps'

const _renderOption = (items) =>
  items.map((item) => (
    <ASelect.Option key={item.id} value={item.value || item.id}>
      {item.label}
    </ASelect.Option>
  ))

class Select extends React.Component {
  render() {
    const { value, onChange, children, items, disabled, empty, mode, style, placeholder } =
      this.props
    return (
      <ASelect
        allowClear
        mode={mode}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        style={style}
        onChange={(f) => onChange(f)}
      >
        {empty && <ASelect.Option value="" />}
        {items && _renderOption(items)}
        {children}
      </ASelect>
    )
  }
}

export default fieldProps(Select)
