import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import { FormattedMessage } from 'react-intl'

const { TabPane } = Tabs

class TranslationForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'translations.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.prefix" obb name="prefix" />
            <Field label="global.label" obb name="label" />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.translations'} />
            </b>
            <Field
              label="global.italian"
              name="it"
              obb
            />
            <Field
              label="global.english"
              name="en"
              obb
            />
            <Field
              label="global.spanish"
              name="es"
              obb
            />
          </div>
        </TabPane>
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/translations',
  title: <FormattedMessage id={'global.edit_translations'} />,
  width: 1200,
})(TranslationForm)
