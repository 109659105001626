import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { printName, renderDate } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.name'} />,
    dataIndex: 'fullName',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.email'} />,
    dataIndex: 'email',
    sorter: true,
    width: 220,
  },
  {
    title: <FormattedMessage id={'global.phone'} />,
    dataIndex: 'phone',
    className: 'text_center',
    sorter: true,
    width: 170,
  },
  {
    title: <FormattedMessage id={'global.mobile'} />,
    dataIndex: 'mobile',
    className: 'text_center',
    sorter: true,
    width: 170,
  },
  {
    title: <FormattedMessage id={'global.country'} />,
    dataIndex: 'country',
    sorter: true,
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.city'} />,
    dataIndex: 'city',
    sorter: true,
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.account'} />,
    dataIndex: 'account',
    sorter: true,
    render: printName,
    width: 170,
  },
  {
    title: <FormattedMessage id={'global.status'} />,
    dataIndex: 'contactStatus',
    sorter: true,
    width: 160,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.source'} />,
    dataIndex: 'contactSource',
    sorter: true,
    width: 160,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.job_title'} />,
    dataIndex: 'jobTitle',
    sorter: true,
    width: 210,
  },
  {
    title: <FormattedMessage id={'global.department'} />,
    dataIndex: 'department',
    sorter: true,
    width: 160,
  },
  {
    title: 'Fax',
    dataIndex: 'fax',
    className: 'text_center',
    sorter: true,
    width: 120,
  },
  {
    title: <FormattedMessage id={'global.assistant_name'} />,
    dataIndex: 'assistantName',
    sorter: true,
    width: 180,
  },
  {
    title: <FormattedMessage id={'global.assistant_phone'} />,
    dataIndex: 'assistantPhone',
    className: 'text_center',
    sorter: true,
    width: 190,
  },
  {
    title: <FormattedMessage id={'global.street'} />,
    dataIndex: 'street',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.state'} />,
    dataIndex: 'state',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.zip_code'} />,
    dataIndex: 'zipCode',
    sorter: true,
    className: 'text-center',
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const ContactsField = ({ campaign, account, deal }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Contact'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.contacts'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_contact'} />}
            onClick={() => {
              modals.open(MODAL_NAME.contact, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: {
                  campaigns: campaign ? [campaign] : undefined,
                  account,
                  deals: deal ? [deal] : undefined,
                },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/contacts'}
    modalKey={MODAL_NAME.contact}
    filters={{ campaigns: campaign, account, deals: deal }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default ContactsField
