import * as React from 'react'
import { connect } from 'react-redux'
// import formWithModel from 'pages/applets/HOC/formWithModel'
import navigatorConfigs from '../../config/modals'
import { Creators } from '../../redux/modals'

const Empty = (message = 'Non Trovato') => () => <div>{message}</div>

class NavigatorGlobalScreens extends React.Component {
  /* appletsMap = {}

  appletsScreenMap = {}

  constructor(props) {
    super(props)
    props.applets.applets.forEach(app => {
      this.appletsMap[app.modal] = app
    })
    / * import(/ * webpackChunkName: "applets.page" * / 'pages/applets/HOC/formWithModel').then(hoc => {
      this.formWithModel = hoc.default
    }) * /
  } */

  // fix caricamento lento
  /* componentWillReceiveProps(props) {
    if (props.applets.applets.length !== this.props.applets.applets.length) {
      props.applets.applets.forEach(app => {
        this.appletsMap[app.modal] = app
      })
    }
  } */

  _closeForm = ({ onClose }, index) => () => {
    try {
      if (onClose) onClose()
    } catch (e) {
      console.log(e)
    }
    // callback from dialog
    this.props.closeForm(index)
  }

  _getScreen = type => {
    /* if (this.appletsScreenMap[type]) {
      console.log(`screen trovata: ${type}`)
      return this.appletsScreenMap[type]
    }
    if (this.appletsMap[type]) {
      this.appletsScreenMap[type] = formWithModel(this.appletsMap[type])
      / * loadable(this.appletsMap[type])(() =>
        import(/ * webpackChunkName: "applets.page" * / 'pages/applets/HOC/formWithModel'),
      ) * /
      return this.appletsScreenMap[type]
    } */
    return Empty(`Modal ${type} non trovata`)
    // if(this.formHoc == null) this.formHoc = await import(/* webpackChunkName: "applets.page" */ 'pages/applets/HOC/formWithModel')
  }

  _renderDialog = (item, index) => {
    let Screen
    // inline Screen
    let props = {}
    if (navigatorConfigs[item._type]) {
      console.log(item._type)
      Screen = navigatorConfigs[item._type].screen
      if (navigatorConfigs[item._type].props) {
        // eslint-disable-next-line prefer-destructuring
        props = navigatorConfigs[item._type].props
      }
    } else if (item._screen) {
      Screen = item._screen
    } else {
      Screen = this._getScreen(item._type)
    }
    return (
      <Screen
        key={`${item._type}-${index}`}
        {...props}
        {...item}
        onRequestClose={item.onClose}
        onClose={this._closeForm(item, index)}
      />
    )
  }

  render() {
    const { navigator } = this.props
    // const keys = Object.keys(navigator);
    return <div>{navigator.items.map(this._renderDialog)}</div>
  }
}
const mapState = state => ({
  navigator: state.modals,
  // applets: state.applets,
})
const mapDispatch = dispatch => ({
  closeForm: key => dispatch(Creators.close(key)),
})
export default connect(
  mapState,
  mapDispatch,
)(NavigatorGlobalScreens)
