import ContactForm from '../pages/crm/ContactsPage/ContactForm'
import LeadForm from '../pages/crm/LeadsPage/LeadForm'
import CampaignForm from '../pages/crm/CampaignsPage/CampaignForm'
import AccountForm from '../pages/crm/AccountsPage/AccountForm'
import DealForm from '../pages/crm/DealsPage/DealForm'
import InvoiceForm from '../pages/crm/InvoicesPage/InvoiceForm'
import QuoteForm from '../pages/crm/QuotesPage/QuoteForm'
import OrderForm from '../pages/crm/OrdersPage/OrderForm'
import TicketForm from '../pages/crm/TicketsPage/TicketForm'
import ServiceContractForm from '../pages/crm/ServiceContractsPage/ServiceContractForm'
import ProductForm from '../pages/crm/ProductsPage/ProductForm'
import VendorForm from '../pages/crm/VendorsPage/VendorForm'
import PaymentForm from '../pages/crm/PaymentsPage/PaymentForm'
import DocumentForm from '../pages/crm/DocumentsPage/DocumentForm'
import { DialogFilePreview } from '../components/platform/File/FilePreview'
import EventForm from '../pages/crm/CalendarPage/EventForm'
import UserForm from '../pages/crm/UsersPage/UserForm'
import EmailForm from '../pages/crm/EmailsPage/EmailForm'
import TaskForm from '../pages/crm/TasksPage/TaskForm'
import TranslationForm from "../pages/crm/TranslationPage/TranslationForm";
import {OpenDialog} from '../components/platform/Dialog/OpenDialog'
import SubscriptionForm from '../pages/crm/SubscriptionsPage/SubscriptionForm'
import PaymentImport from '../pages/crm/PaymentsPage/PaymentImport'
import { IssueDetailForm } from '../pages/jira/issues/components/IssueDetailForm'
import { PostForm } from '../pages/knowledge/posts/PostForm'

export const MODAL_NAME = {
  contact: 'contacts',
  lead: 'leads',
  campaign: 'campaigns',
  account: 'accounts',
  deal: 'deals',
  quote: 'quotes',
  invoice: 'invoices',
  order: 'orders',
  ticket: 'tickets',
  service_contract: 'service_contracts',
  product: 'products',
  vendor: 'vendors',
  payment: 'payments',
  document: 'documents',
  event: 'events',
  user: 'users',
  task: 'tasks',
  translation: 'translations',
  email: 'emails',

  expert: 'experts',
  lawyer: 'lawyers',
  insured: 'insureds',
  office: 'offices',
  broker: 'brokers',

  filePreview: 'filePreview',
  subscription: 'subscription',
  paymentImport: 'paymentImport',
  issueDetail: 'issueDetail',
  post: 'post'

}

const modals = {
  [MODAL_NAME.event]: {
    screen: EventForm,
  },
  [MODAL_NAME.contact]: {
    screen: ContactForm,
  },
  [MODAL_NAME.lead]: {
    screen: LeadForm,
  },
  [MODAL_NAME.campaign]: {
    screen: CampaignForm,
  },
  [MODAL_NAME.account]: {
    screen: AccountForm,
  },
  [MODAL_NAME.deal]: {
    screen: DealForm,
  },
  [MODAL_NAME.quote]: {
    screen: QuoteForm,
  },
  [MODAL_NAME.invoice]: {
    screen: InvoiceForm,
  },
  [MODAL_NAME.order]: {
    screen: OrderForm,
  },
  [MODAL_NAME.ticket]: {
    screen: TicketForm,
  },
  [MODAL_NAME.service_contract]: {
    screen: ServiceContractForm,
  },
  [MODAL_NAME.subscription]: {
    screen: SubscriptionForm,
  },
  [MODAL_NAME.product]: {
    screen: ProductForm,
  },
  [MODAL_NAME.vendor]: {
    screen: VendorForm,
  },
  [MODAL_NAME.payment]: {
    screen: PaymentForm,
  },
  [MODAL_NAME.paymentImport]: {
    screen: PaymentImport,
  },

  [MODAL_NAME.document]: {
    screen: DocumentForm,
  },
  [MODAL_NAME.user]: {
    screen: UserForm,
  },
  [MODAL_NAME.task]: {
    screen: TaskForm,
  },
  [MODAL_NAME.translation]: {
    screen: TranslationForm,
  },
  [MODAL_NAME.email]: {
    screen: EmailForm,
  },
  [MODAL_NAME.issueDetail]: {
    screen: IssueDetailForm,
  },
  [MODAL_NAME.post]: {
    screen: PostForm,
  },
  // qui altre modal
  [MODAL_NAME.filePreview]: {
    screen: DialogFilePreview,
  },
  openEditor: {
    screen: OpenDialog,
  },
}

export default modals
