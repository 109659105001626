/* eslint-disable react/destructuring-assignment */
/* global $ */
import React from 'react'
import visionClient from 'services/axios'
import { Skeleton } from 'antd'

export default class JAutocomplete extends React.Component {
  static defaultProps = {
    getValue: null,
    filter: 'filter',
  }

  constructor(props) {
    super(props)
    this.state = {
      label: this.props.field.value || '',
      loading: true,
    }
  }

  /* componentWillReceiveProps(props){
    if(props.source!=this.props.source){
      const field =  $(this.input);
      field.autocomplete('option', 'source', props.source)
    }
  } */

  async componentDidMount() {
    const { options, source, useIris } = this.props
    const field = $(this.input)

    await this._init(source, useIris)

    field
      .autocomplete({
        minLength: 0,
        source: (request, response) => {
          visionClient
            .get(source, {
              params: {
                [this.props.filter]: request.term,
              },
            })
            .then(({ data }) => {
              const items = data['@context'] ? data['hydra:member'] : data
              response(items.map(this._getItemValues))
            })
        },
        select: (event, ui) => {
          this.setState({ label: ui && ui.item ? ui.item.label : '' }, () => {
            this._onChange(ui.item)
          })
        },
        change: (event, ui) => {
          if (ui.item == null) {
            this.setState({ label: '' }, () => {
              this._onChange(null)
            })
          }
        },
        ...options,
      })
      .click(() => field.autocomplete('search'))

    field.blur(() => {
      if (field.val().trim() === '') {
        this._onChange(null)
      }
    })
  }

  _init = async (source, useIris) => {
    if ((useIris && this.props.field.value) || this.props.field.value > 0) {
      try {
        const response = await visionClient.get(
          useIris ? this.props.field.value : `${source}/${this.props.field.value}`,
        )
        return this._setState({
          label: this._getLabelValue(response.data),
          loading: false,
        })
      } catch (e) {
        return this._setState({ label: `Not found id: ${this.props.field.value}` })
      }
    } else {
      return this._setState({ loading: false })
    }
  }

  _setState = (state) => new Promise((resolve) => this.setState(state, resolve))

  _getItemValues = (i) => {
    return {
      ...i,
      id: i[this.props.valueKey || 'id'],
      label: this._getLabelValue(i),
    }
  }

  _onChange = (item) => {
    if (this.props.setValues) {
      return this.props.form.setValues({
        ...this.props.form.values,
        ...this.props.setValues(item),
      })
    }
    return this.props.form.setFieldValue(this.props.field.name, item ? item.id : null)
  }

  _getLabelValue = (i) => {
    if (this.props.getLabel) {
      return this.props.getLabel(i)
    }
    return i[this.props.valueLabel || 'label']
    // return i.label
    // return `${i.first_name} ${i.last_name}`
  }

  render() {
    // let {value, strict, source, onChange} = this.props
    const hide = this.state.loading ? 'd-none' : ''
    return (
      <>
        {this.state.loading && <Skeleton.Input active style={{ margin: '3px 0 0' }} />}
        <input
          className={`form-control input-sm ${hide}`}
          onChange={({ target }) => {
            this.setState({ label: target.value })
          }}
          value={this.state.label}
          ref={(input) => {
            this.input = input
          }}
        />
      </>
    )
  }
}
