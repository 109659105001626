import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'

const { TabPane } = Tabs

class VendorForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'vendors.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.name" obb name="name" />
            <Field
              label="global.category"
              name="vendorCategory"
              type={'vendor_category'}
              component={ItemCodeSelect}
            />
            <Field label="global.phone" name="phone" />
            <Field label="global.mobile" name="mobile" />
            <Field label="global.email" name="email" />
            <Field label="global.secondary_email" name="secondaryEmail" />
            <Field label="Fax" name="fax" />
            <Field label="Skype ID" name="skypeId" />
            <Field label="global.street" name="street" />
            <Field label="global.city" name="city" />
            <Field label="global.zip_code" name="zipCode" />
            <Field label="global.state" name="state" />
            <Field label="global.country" name="country" />
            <b className={'span2'}>
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/vendors',
  title: <FormattedMessage id={'global.edit_vendor'} />,
  width: 1200,
})(VendorForm)
