import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { renderDate } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'
import format from 'date-fns/format'

const columns = [
  {
    title: <FormattedMessage id={'global.type'} />,
    dataIndex: 'eventType',
    sorter: true,
    width: 120,
  },
  {
    title: <FormattedMessage id={'global.title'} />,
    dataIndex: 'title',
    sorter: true,
    width: 120,
  },
  {
    title: <FormattedMessage id={'global.description'} />,
    dataIndex: 'description',
    sorter: true,
  },
  {
    title: <FormattedMessage id={'global.start'} />,
    dataIndex: 'start',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 140,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const today = format(new Date(), 'yyyy-MM-dd')

const EventsField = ({ contact, account, lead, deal }) => (
  <Crud
    pageLayout={'inline'}
    defaultItem={{
      start: new Date(),
      allDay: true,
    }}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.events'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_event'} />}
            onClick={() => {
              modals.open(MODAL_NAME.event, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: {
                  contact,
                  account,
                  eventLead: lead,
                  deal,
                  allDay: true,
                  start: today,
                  date: today,
                },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/events'}
    modalKey={MODAL_NAME.event}
    filters={{ contact, account, eventLead: lead, deal }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default EventsField
