import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { renderDate, printName } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.name'} />,
    dataIndex: 'name',
    sorter: true,
    width: 230,
  },
  {
    title: <FormattedMessage id={'global.status'} />,
    dataIndex: 'campaignStatus',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.type'} />,
    dataIndex: 'campaignType',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.product'} />,
    dataIndex: 'product',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.target_audience'} />,
    dataIndex: 'targetAudience',
    sorter: true,
    className: 'text-center',
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.budget_cost'} />,
    dataIndex: 'budgetCost',
    sorter: true,
    className: 'text-center',
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.actual_budget'} />,
    dataIndex: 'actualBudget',
    sorter: true,
    className: 'text-center',
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.expected_revenue'} />,
    dataIndex: 'expectedRevenue',
    sorter: true,
    className: 'text-center',
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.expected_sales_count'} />,
    dataIndex: 'expectedSalesCount',
    sorter: true,
    className: 'text-center',
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.actual_sales_count'} />,
    dataIndex: 'actualSalesCount',
    sorter: true,
    className: 'text-center',
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.expected_response_count'} />,
    dataIndex: 'expectedResponseCount',
    sorter: true,
    className: 'text-center',
    width: 140,
  },
  {
    title: <FormattedMessage id={'global.actual_response_count'} />,
    dataIndex: 'actualResponseCount',
    sorter: true,
    className: 'text-center',
    width: 180,
  },
  {
    title: <FormattedMessage id={'global.expected_roi'} />,
    dataIndex: 'expectedRoi',
    sorter: true,
    className: 'text-center',
    width: 140,
  },
  {
    title: <FormattedMessage id={'global.actual_roi'} />,
    dataIndex: 'actualRoi',
    sorter: true,
    className: 'text-center',
    width: 130,
  },
  {
    title: <FormattedMessage id={'global.sponsor'} />,
    dataIndex: 'sponsor',
    sorter: true,
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.expected_close_date'} />,
    dataIndex: 'expectedCloseDate',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.target_size'} />,
    dataIndex: 'targetSize',
    sorter: true,
    className: 'text-center',
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const CampaignsField = ({ contact, lead, account }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Campaigns'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.campaigns'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_campaign'} />}
            onClick={() => {
              modals.open(MODAL_NAME.campaign, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: {
                  contacts: contact ? [contact] : undefined,
                  leads: lead ? [lead] : undefined,
                  accounts: account ? [account] : undefined,
                },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/campaigns'}
    modalKey={MODAL_NAME.campaign}
    filters={{ contacts: contact, leads: lead, accounts: account }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default CampaignsField
