import React from 'react'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import withNavigator from 'components/platform/hocs/withNavigator'
import FileSelector from '../../../components/platform/form/inputs/FileSelector'

const PaymentImport = () => (
  <div>
    <Field
      name={'file'}
      label={null}
      component={FileSelector}
    />
  </div>
)

export default withNavigator(
  withModalForm({
    apiResource: '/api/payments/import',
    title: 'Importa Excel',
    width: 600,
    multipart: true,
  })(PaymentImport),
)
