import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import {renderDate, printName, printFullName} from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: <FormattedMessage id={'global.tasks_title'} />,
    dataIndex: 'title',
    sorter: true,
    className: 'text-center',
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.due_date'} />,
    dataIndex: 'endDate',
    sorter: true,
    width: 150,
    render: renderDate,
  },
  {
    title: <FormattedMessage id={'global.description'} />,
    dataIndex: 'description',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.tasks_assigned_user'} />,
    dataIndex: 'userAssign',
    sorter: true,
    width: 150,
    render: printFullName,
  },
  {
    title: <FormattedMessage id={'global.account'} />,
    dataIndex: 'account',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.priority'} />,
    dataIndex: 'taskPriority',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.status'} />,
    dataIndex: 'taskStatus',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.contact'} />,
    dataIndex: 'contact',
    sorter: true,
    width: 150,
    render: printFullName,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const TasksField = ({ contact, account }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin tasks'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.tasks'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_task'} />}
            onClick={() => {
              modals.open(MODAL_NAME.task, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: { contact, account },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/tasks'}
    modalKey={MODAL_NAME.task}
    filters={{ contact, account }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default TasksField
