import React, {useMemo} from "react";
import { useFormikContext} from "formik";
import {withModalForm} from "../../../../components/platform/hocs/Form";
import {BASE_URL} from "../../../../services/jira/jiraClient";
import './styles.scss'

const Form = () => {
  const form = useFormikContext<any>()
  const issue = form.values

  const description =  useMemo(() => {
    let des = issue.renderedFields?.description
    // attach by filenaname
    // let attachments = issue.renderedFields?.attachment
    const domain = (new URL(issue.self)).hostname;
    const images  = des.match(/(\/secure\/attachment\/[^ ]*)/ig)

    if(!images) {
      return des
    }
    // console.log('images', images, domain)
    images.forEach((image: any) => {
      console.log(image, `https://${domain}${image}`)
      des = des.replace(image, `https://${domain}${image}`) // attch.thumbnail
    })
    /* attachments.forEach((attch: any) => {
      images.forEach((image: any) => {
        if(image.indexOf(attch.filename) > -1) {
          des = des.replace(image, `"${attch.content}"`) // attch.thumbnail
        }
      })

    }) */

    // remove all width and height attributes
    return des.replaceAll(/(width|height)="[^"]*"/g, '')
  },[issue.renderedFields?.description,issue.self])

  return (
    <>
      <div style={{width: 800}} className={"prose p-0"}>
        <div dangerouslySetInnerHTML={{__html: description}}  />
      </div>
      <div className={"leading-tight text-gray-500 font-light"}> <a target={"_blank"} href={`https://performa.atlassian.net/browse/${issue.key}`} rel="noreferrer">{issue.key}</a></div>
    </>
  )
}
//       <pre>{JSON.stringify(issue, null,2)}</pre>

export const IssueDetailForm = withModalForm({
  apiResource: `${BASE_URL}/api/jira/issues`,
  title: ({remote}: any) => remote?.item ? `${remote?.item?.key} - ${remote?.item?.fields?.summary}` : "",
  buttons: false
} as any)(Form)
