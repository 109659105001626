import React from 'react'
import Crud from 'components/platform/Crud'
import { printName, renderDate } from 'components/platform/Table/renders'
import { MODAL_NAME } from 'config/modals'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.name'} />,
    dataIndex: 'name',
    sorter: true,
    width: 225,
  },
  {
    title: <FormattedMessage id={'global.shipping'} />,
    dataIndex: 'shipping',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.valid_until'} />,
    dataIndex: 'validUntil',
    sorter: true,
    className: 'text-center',
    width: 120,
    render: renderDate,
  },
  {
    title: <FormattedMessage id={'global.discount'} />,
    dataIndex: 'discount',
    sorter: true,
    className: 'text-center',
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.amount'} />,
    dataIndex: 'amount',
    sorter: true,
    className: 'text-center',
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.delivery_cost'} />,
    dataIndex: 'deliveryCost',
    sorter: true,
    className: 'text-center',
    width: 140,
  },
  {
    title: <FormattedMessage id={'global.stage'} />,
    dataIndex: 'quoteStage',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.carrier'} />,
    dataIndex: 'quoteCarrier',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.currency'} />,
    dataIndex: 'currency',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.tax'} />,
    dataIndex: 'tax',
    sorter: true,
    className: 'text-center',
    width: 80,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const QuotesField = ({ contact, account }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Quote'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.quotes'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_quote'} />}
            onClick={() => {
              modals.open(MODAL_NAME.quote, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: { contact, account },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/quotes'}
    modalKey={MODAL_NAME.quote}
    filters={{ contact, account }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default QuotesField
