import * as React from 'react'
import moment from 'moment'
import { TimePicker as AnTimePicker } from 'antd'
import fieldProps from './fieldProps'

const format = 'HH:mm'
// const monthFormat = 'YYYY/MM';

class Timepicker extends React.Component {
  //  onChange={m => onChange(m ? m.format(format) : m)}
  render() {
    const { onChange, value, placeholder, style } = this.props
    return (
      <AnTimePicker
        popupClassName={'lp-timepicker'}
        onSelect={(m) => onChange(m ? m.format(format) : m)}
        value={value ? moment(value, format) : null}
        format={format}
        style={style}
        placeholder={placeholder}
      />
    )
  }
}

export default fieldProps(Timepicker)
