import * as React from 'react'
import { Button, Layout, Dropdown, Menu } from 'antd'
import { Form, Formik } from 'formik'
import SideBarLayoutPage from 'components/platform/Crud/Layouts/SideBarLayoutPage'
import HorizontalLayoutPage from 'components/platform/Crud/Layouts/HorizontalLayoutPage'
import InlineLayout from 'components/platform/Crud/Layouts/InlineLayout'
import { Message, resourceClient, showDeleteConfirm } from 'components/platform/hocs/Form'
import withNavigator from 'components/platform/hocs/withNavigator'
import { FormattedMessage } from 'react-intl'
import Table from '../Table'
import { getWindowHeight } from '../utils'

const { Content } = Layout


class Crud extends React.Component {
  static defaultProps = {
    filters: {},
    pageLayout: 'horizontal',
    actionButtonType: 'edit',
    identifierKey: 'id',
  }

  constructor(props) {
    super(props)

    let actionButton
    if (props.actionButtonType === 'edit') {
      actionButton = {
        title: '',
        dataIndex: 'id',
        className: 'text-right',
        fixed: 'right',
        width: 50,
        render: (col, row) => (
          <Button
            type="primary"
            size={'small'}
            onClick={() => this.openForm(row[props.identifierKey])}
            icon={<i className="fa fa-pencil" />}
          />
        ),
      }
    } else if (props.actionButtonType === 'context-menu') {
      actionButton = {
        title: '',
        dataIndex: 'id',
        className: 'text-right',
        width: 50,
        render: (col, row) => (
          <Button
            type="primary"
            size={'small'}
            onClick={() => this._openContextMenu(row)}
            icon={<i className="fa fa-menu" />}
          />
        ),
      }
    }

    const initialColumns = actionButton
      ? [...this.props.tableColumns, actionButton]
      : this.props.tableColumns

    const initialSearchValues = this.props.searchValues

    this.state = {
      search: {
        ...initialSearchValues,
      },
      popup: {
        visible: false,
        x: 0,
        y: 0,
      },
      selection: [],
      tableData: null,
      columnsFinal: initialColumns,
    }
  }

  componentDidUpdate(props) {
    if (props.tableColumns !== this.props.tableColumns) {
      let actionButton
      if (props.actionButtonType === 'edit') {
        actionButton = {
          title: '',
          dataIndex: 'id',
          className: 'text-right',
          fixed: 'right',
          width: 50,
          render: (col, row) => (
            <Button
              type="primary"
              size={'small'}
              onClick={() => this.openForm(row[props.identifierKey])}
              icon={<i className="fa fa-pencil" />}
            />
          ),
        }
      } else if (props.actionButtonType === 'context-menu') {
        actionButton = {
          title: '',
          dataIndex: 'id',
          className: 'text-right',
          width: 50,
          render: (col, row) => (
            <Button
              type="primary"
              size={'small'}
              onClick={() => this._openContextMenu(row)}
              icon={<i className="fa fa-menu" />}
            />
          ),
        }
      }

      const initialColumns = actionButton
        ? [...this.props.tableColumns, actionButton]
        : this.props.tableColumns
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ columnsFinal: initialColumns })
    }
  }

  onRow = record => ({
    onContextMenu: event => {
      event.preventDefault()
      this._openContextMenu(record)
    },
  })

  _openContextMenu = record => {
    this.setState({
      popup: {
        record,
        visible: true,
      },
    })
  }

  _setContextVisible = (visible, cb) => {
    this.setState(
      ({ popup }) => ({
        popup: {
          ...popup,
          visible,
        },
      }),
      cb,
    )
  }

  _onActionClick = e => {
    if (e.key === 'edit') {
      this._setContextVisible(false, () => this.openForm(this.state.popup.record[this.props.identifierKey]))
    }
    if (e.key === 'delete') {
      this._setContextVisible(false, () => this._confirmDelete(this.state.popup.record[this.props.identifierKey]))
    }
  }

  _confirmDelete = id => {
    showDeleteConfirm('Sei sicuro di voler eleminare questo record?', async () => {
      const response = await resourceClient.deleteItem(this.props.apiResource, id)
      if (this.props.onRemoteDeleted && response.status === 204) {
        this.props.onRemoteDeleted({ id, iris: `${this.props.apiResource}/${id}` })
      }
      this.table.fetch()
      Message.show({ data: { error: false, message: 'Eliminato correttamente' } })
    })
  }

  openForm(id) {
    if (!this.props.modalKey) return
    console.log('itemId', id)
    this.props.modals.open(this.props.modalKey, {
      itemId: id,
      onClose: () => this.table.fetch(),
      onRemoteSaved: this.props.onRemoteSaved,
      onRemoteDeleted: this.props.onRemoteDeleted,
      defaultItem: this.props.defaultItem,
    })
    // modals.open(modal, { itemId: 0, onClose: () => this.table.fetch() }
  }

  render() {
    const { search, selection, tableData, columnsFinal } = this.state

    const {
      pageLayout,
      modals,
      auth,
      searchComponent: Search,
      searchValues,
      renderSearch,
      sidebarWidth,
      debug,
      rowClassName,
      apiResource,
      tableHeader,
      tableSize = 'small',
      tableSorter,
      renderContextMenu,
      pagination = true,
      pageTitle: title,
      scrollX = undefined,
      scrollY = undefined,
      scrollAuto = true,
      onTableDataFetch
    } = this.props

    let PageLayout
    if (pageLayout === 'horizontal') {
      PageLayout = HorizontalLayoutPage
    } else if (pageLayout === 'inline') {
      PageLayout = InlineLayout
    } else {
      PageLayout = SideBarLayoutPage
    }

    const menu = renderContextMenu ? (
      renderContextMenu({ onActionClick: this._onActionClick })
    ) : (
      <Menu onClick={this._onActionClick}>
        <Menu.Item key="edit">
          <i className={'fa fa-pencil'} /> <FormattedMessage id={'global.edit'} />
        </Menu.Item>
        <Menu.Item key="delete">
          <i className={'fa fa-trash'} /> <FormattedMessage id={'global.delete'} />
        </Menu.Item>
      </Menu>
    )

    return (
      <PageLayout
        title={title}
        sidebarWidth={sidebarWidth}
        ref={layout => {
          this.layout = layout
        }}
        sidebar={
          !!Search || !!renderSearch ? (
            <Formik
              innerRef={node => {
                this.form = node
              }}
              initialValues={search}
              onSubmit={(values /* , { setSubmitting } */) => {
                // ,()=>this.form.submitForm()
                this.setState({ search: values }, () => this.table.fetch())
              }}
            >
              {({ isSubmitting, ...props }) => {
                const searchProps = {
                  form: props,
                  tableData,
                  auth,
                  selection,
                  modals,
                  getTable: () => this.table,
                  onNewClick: () => this.openForm(0),
                  onToggleClick: () => this.layout.toggle(),
                  onSubmitClick: () => this.form.submitForm(),
                  onClearClick: () =>
                    this.setState({ search: searchValues }, () => {
                      props.setValues(searchValues)
                      this.table.fetch()
                    }),
                }
                const searchContent = renderSearch ? (
                  renderSearch(searchProps)
                ) : (
                  <Search {...searchProps} />
                )
                return <Form>{searchContent}</Form>
              }}
            </Formik>
          ) : null
        }
        content={
          <Content>
            <section className="card" style={{ margin: 5 }}>
              <Dropdown
                onVisibleChange={s => {
                  if (!s) this._setContextVisible(false)
                }}
                visible={this.state.popup.visible}
                trigger={['contextMenu']}
                overlay={menu}
              >
                <div className="card-body crud-container" style={{ padding: 0 }}>
                  <Table
                    key={apiResource}
                    debug={debug}
                    ref={table => {
                      this.table = table
                    }}
                    source={apiResource}
                    onRow={this.onRow}
                    parms={{
                      ...this.props.filters,
                      ...search,
                    }}
                    scroll={{
                      x: scrollAuto ? 900 : scrollX, // 900,
                      y: scrollAuto ? getWindowHeight() - 200 : scrollY, // scrollY !== null ? scrollY : this.props.height - 80,
                      scrollToFirstRowOnChange: true,
                    }}
                    size={tableSize}
                    columns={columnsFinal}
                    rowClassName={rowClassName}
                    showHeader={tableHeader}
                    pagination={pagination}
                    sorter={tableSorter}
                    onDataFetch={onTableDataFetch}
                  />
                </div>
              </Dropdown>
            </section>
          </Content>
        }
      />
    )
  }
}

export default withNavigator(Crud)
