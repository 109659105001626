import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import InputNumber from 'components/platform/form/inputs/InputNumber'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'
import LeadsField from '../LeadsPage/LeadsField'
import ContactsField from '../ContactsPage/ContactsField'
import DealsField from '../DealsPage/DealsField'
import AccountsField from '../AccountsPage/AccountsField'

const { TabPane } = Tabs

class CampaignForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'campaigns.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.name" obb name="name" />
            <Field label="global.product" name="product" />
            <Field label="global.target_audience" name="targetAudience" component={InputNumber} />
            <Field
              label="global.expected_close_date"
              name="expectedCloseDate"
              component={Datepicker}
            />
            <Field label="global.sponsor" name="sponsor" />
            <Field label="global.target_size" name="targetSize" component={InputNumber} />
            <Field
              label="global.status"
              name="campaignStatus"
              type={'campaign_status'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.type"
              name="campaignType"
              type={'campaign_type'}
              component={ItemCodeSelect}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.expectations_and_actuals'} />
            </b>
            <Field label="global.budget_cost" name="budgetCost" component={InputNumber} />
            <Field label="global.actual_budget" name="actualBudget" component={InputNumber} />
            <Field
              label="global.actual_response_count"
              name="actualResponseCount"
              component={InputNumber}
            />
            <Field label="global.expected_revenue" name="expectedRevenue" component={InputNumber} />
            <Field
              label="global.expected_sales_count"
              name="expectedSalesCount"
              component={InputNumber}
            />
            <Field
              label="global.actual_sales_count"
              name="actualSalesCount"
              component={InputNumber}
            />
            <Field
              label="global.expected_sales_count"
              name="expectedResponseCount"
              component={InputNumber}
            />
            <Field label="global.expected_roi" name="expectedRoi" component={InputNumber} />
            <Field label="global.actual_roi" name="actualRoi" component={InputNumber} />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.leads'} />} key={'campaigns.leads'}>
            <LeadsField campaign={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.contacts'} />} key={'campaigns.contacts'}>
            <ContactsField campaign={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.accounts'} />} key={'campaigns.accounts'}>
            <AccountsField campaign={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.deals'} />} key={'campaigns.deals'}>
            <DealsField campaign={this.props.form.values['@id']} />
          </TabPane>
        )}
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/campaigns',
  title: <FormattedMessage id={'global.edit_campaign'} />,
  width: 1200,
})(CampaignForm)
