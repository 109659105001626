import React from 'react'
import Dialog from './index'


export const OpenDialog = ({onClose, sourceMap, onClick}) => {
  return (
    <Dialog
      opened
      modal
      onClose={onClose}
      title={"Select file"}
    >
      <div>
        {sourceMap.map(file => (
          <div
            onClick={() => onClick(file)}
            style={{fontSize: 11, padding:3, borderBottom: "1px solid #DDD", cursor: "pointer"}}
            key={file.fileName}
          >
            {file.fileName}
          </div>
        ))}
      </div>
    </Dialog>
  )
}
