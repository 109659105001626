import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { printName, renderDate } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.title'} />,
    dataIndex: 'title',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.category'} />,
    dataIndex: 'documentCategory',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.status'} />,
    dataIndex: 'documentStatus',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.type'} />,
    dataIndex: 'documentType',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const DocumentsField = (props) => {
  const { lead, contact, account, servicesContract } = props
  return (
    <Crud
      pageLayout={'inline'}
      renderSearch={({ modals, getTable }) => (
        <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
          <FormattedMessage id={'global.documents'} />
          <div className={'pull-right'}>
            <NewButton
              title={<FormattedMessage id={'global.new_document'} />}
              onClick={() => {
                modals.open(MODAL_NAME.document, {
                  itemId: 0,
                  onClose: () => {
                    setTimeout(() => getTable().fetch(), 400)
                  },
                  // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                  defaultItem: {
                    leads: lead ? [lead] : undefined,
                    contacts: contact ? [contact] : undefined,
                    accounts: account ? [account] : undefined,
                    servicesContracts: servicesContract ? [servicesContract] : undefined,
                  },
                })
              }}
            />
          </div>
        </div>
      )}
      apiResource={'/api/documents'}
      modalKey={MODAL_NAME.document}
      filters={{
        leads: lead,
        contacts: contact,
        accounts: account,
        servicesContracts: servicesContract,
      }}
      searchValues={{ filter: '' }}
      tableSorter={{ field: 'id', order: 'desc' }}
      tableColumns={columns}
    />
  )
}

export default DocumentsField
