import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import JAutocomplete from 'components/platform/form/inputs/JAutocomplete'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'

const { TabPane } = Tabs

class TicketForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'tickets.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.ticket_number" obb name="name" />
            <Field label="global.due_date" name="dueDate" component={Datepicker} />
            <Field
              label="global.priority"
              name="ticketPriority"
              type={'ticket_priority'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.status"
              name="ticketStatus"
              type={'ticket_status'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.severity"
              name="ticketSeverity"
              type={'ticket_severity'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.category"
              name="ticketCategory"
              type={'ticket_category'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.contact"
              name="contact"
              useIris
              valueKey={'@id'}
              valueLabel={'fullName'}
              source={'/api/contacts'}
              component={JAutocomplete}
              getLabel={i => `${i.fullName}`}
              filter={'fullName'}
            />
            <Field
              label="global.account"
              name="account"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/accounts'}
              component={JAutocomplete}
              getLabel={i => `${i.name}`}
              filter={'name'}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.description'} />
            </b>
            <Field
              className={'span2'}
              label={'global.description'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="description"
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.resolution'} />
            </b>
            <Field
              className={'span2'}
              label={'global.resolution'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="resolution"
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/tickets',
  title: <FormattedMessage id={'global.edit_ticket'} />,
  width: 1200,
})(TicketForm)
