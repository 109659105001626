import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { printName, renderDate } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.name'} />,
    dataIndex: 'fullName',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.email'} />,
    dataIndex: 'email',
    sorter: true,
    width: 220,
  },
  {
    title: <FormattedMessage id={'global.capture_date'} />,
    dataIndex: 'captureDate',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 170,
  },
  {
    title: <FormattedMessage id={'global.source'} />,
    dataIndex: 'leadSource',
    sorter: true,
    width: 160,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.status'} />,
    dataIndex: 'leadStatus',
    sorter: true,
    width: 160,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.industry'} />,
    dataIndex: 'leadIndustry',
    sorter: true,
    width: 160,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.rating'} />,
    dataIndex: 'leadRating',
    sorter: true,
    width: 160,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.lead_company'} />,
    dataIndex: 'leadCompany',
    sorter: true,
    width: 220,
  },
  {
    title: <FormattedMessage id={'global.secondary_email'} />,
    dataIndex: 'secondaryEmail',
    sorter: true,
    width: 220,
  },
  {
    title: <FormattedMessage id={'global.annual_revenue'} />,
    dataIndex: 'annualRevenue',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.no_of_employees'} />,
    dataIndex: 'noOfEmployees',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.phone'} />,
    dataIndex: 'phone',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.mobile'} />,
    dataIndex: 'mobile',
    sorter: true,
    width: 150,
  },
  {
    title: 'Fax',
    dataIndex: 'fax',
    sorter: true,
    width: 150,
  },
  {
    title: 'Twitter',
    dataIndex: 'twitter',
    sorter: true,
    width: 165,
  },
  {
    title: 'Facebook',
    dataIndex: 'facebook',
    sorter: true,
    width: 165,
  },
  {
    title: 'Skype',
    dataIndex: 'skype',
    sorter: true,
    width: 165,
  },
  {
    title: <FormattedMessage id={'global.website'} />,
    dataIndex: 'website',
    sorter: true,
    width: 170,
  },
  {
    title: <FormattedMessage id={'global.street'} />,
    dataIndex: 'addrStreet',
    sorter: true,
    width: 210,
  },
  {
    title: <FormattedMessage id={'global.state'} />,
    dataIndex: 'addrState',
    sorter: true,
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.country'} />,
    dataIndex: 'addrCountry',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.city'} />,
    dataIndex: 'addrCity',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.zip_code'} />,
    dataIndex: 'addrZip',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.job_title'} />,
    dataIndex: 'jobTitle',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const LeadsField = ({ campaign }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Lead'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.leads'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_lead'} />}
            onClick={() => {
              modals.open(MODAL_NAME.lead, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: { campaigns: [campaign] },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/leads'}
    modalKey={MODAL_NAME.lead}
    filters={{ campaigns: campaign }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default LeadsField
