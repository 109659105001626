export const menus = [
  {
    category: true,
    title: 'global.general',
    key: 'general',
  },
  {
    title: 'global.home',
    key: 'home',
    icon: 'fe fe-home',
    url: '/home',
    // count: 4
  },
  {
    title: 'global.calendar',
    key: 'calendary',
    icon: 'fa fa-calendar',
    url: '/calendary',
    feature: 'calendar',
    // count: 4
  },
  {
    title: 'global.registries',
    key: 'registries',
    icon: 'fa fa-list',
    children: [
      {
        title: 'global.contacts',
        key: 'mark.contacts',
        icon: 'fa fa-address-book',
        url: '/contacts',
        feature: 'contacts',
        // count: 4
      },
      {
        title: 'global.accounts',
        key: 'mark.accounts',
        icon: 'fa fa-building',
        url: '/accounts',
        feature: 'companies',
        // count: 4
      },
      {
        title: 'global.documents',
        key: 'others.documents',
        icon: 'fa fa-files-o',
        url: '/documents',
        feature: 'documents',
        // count: 4
      },
    ]
  },
  {
    title: 'global.marketing',
    key: 'mark',
    icon: 'fa fa-line-chart',
    children: [
      {
        title: 'global.leads',
        key: 'mark.leads',
        icon: 'fa fa-search',
        url: '/leads',
        feature: 'leads',
        // count: 4
      },
      {
        title: 'global.campaigns',
        key: 'mark.campaigns',
        icon: 'fa fa-bullhorn',
        url: '/campaigns',
        feature: 'campaigns',
        // count: 4
      },
    ],
  },
  {
    title: 'global.sales',
    key: 'sales',
    icon: 'fa fa-money',
    children: [
      {
        title: 'global.deals',
        key: 'sales.deals',
        icon: 'fa fa-tags',
        url: '/deals',
        feature: 'deals',
        // count: 4
      },
      {
        title: 'global.quotes',
        key: 'sales.quotes',
        icon: 'fa fa-gift',
        url: '/quotes',
        feature: 'quotes',
        // count: 4
      },
      {
        title: 'global.invoices',
        key: 'sales.invoices',
        icon: 'fa fa-file-text',
        url: '/invoices',
        feature: 'invoices',
        // count: 4
      },
      {
        title: 'global.subscriptions',
        key: 'sales.subscriptions',
        icon: 'fa fa-refresh',
        url: '/subscriptions',
        feature: 'subscriptions',
        // count: 4
      },
      {
        title: 'global.orders',
        key: 'sales.orders',
        icon: 'fa fa-shopping-cart',
        url: '/orders',
        feature: 'orders',
        // count: 4
      },
      {
        title: 'global.payments',
        key: 'others.payments',
        icon: 'fa fa-usd',
        url: '/payments',
        feature: 'payments',
        // count: 4
      },
      {
        title: 'global.service_contract',
        key: 'support.service_contract',
        icon: 'fa fa-handshake-o',
        url: '/service_contracts',
        feature: 'service-contracts',
        // count: 4
      },
      {
        title: 'global.products_services',
        key: 'inventory.product_service',
        icon: 'fa fa-hand-o-up',
        url: '/products_services',
        feature: 'products',
        // count: 4
      },
    ],
  },
  {
    title: 'global.support',
    key: 'support',
    icon: 'fa fa-question',
    children: [
      {
        title: 'global.tickets',
        key: 'support.tickets',
        icon: 'fa fa-ticket',
        url: '/tickets',
        feature: 'tickets',
        // count: 4
      },
      {
        title: 'global.tasks',
        key: 'todos',
        icon: 'fa fa-tasks',
        url: '/tasks',
        feature: 'tasks',
      },
      {
        title: 'global.jira',
        key: 'jira',
        icon: 'fa fa-ticket',
        url: '/jira/issues',
        feature: 'jira',
      },
    ],
  },
  {
    title: 'global.inventory',
    key: 'inventory',
    icon: 'fa fa-check-square-o',
    children: [

      {
        title: 'global.vendors',
        key: 'inventory.vendors',
        icon: 'fa fa-male',
        url: '/vendors',
        feature: 'vendors',
        // count: 4
      },
    ],
  },
  {
    title: 'global.others',
    key: 'others',
    icon: 'fa fa-map-o',
    children: [


    ],
  },
  {
    title: 'global.users',
    key: 'utenti',
    icon: 'fa fa-users',
    url: '/users',
  },
  {
    title: 'Emails',
    key: 'emails',
    icon: 'fa fa-envelope-o',
    url: '/emails',
    feature: 'emails',
  },
  {
    title: 'global.knowledge_base',
    key: 'knowledge_base',
    icon: 'fa fa-lightbulb-o',
    children: [
      {
        title: 'global.articles',
        key: 'knowledge_base.articles',
        icon: 'fa fa-file-text-o',
        url: '/posts',
        feature: 'knowledge-base',
      },
      {
        title: 'global.categories',
        key: 'knowledge_base.categories',
        icon: 'fa fa-list',
        url: '/topics',
        feature: 'knowledge-base',
      },
      {
        title: 'global.learner',
        key: 'knowledge_base.learner',
        icon: 'fa fa-graduation-cap',
        url: '/learner',
        feature: 'knowledge-base',
      }
    ]
  },
  {
    title: 'global.settings',
    key: 'settings',
    icon: 'fe fe-settings',
    url: '/settings',
  },
]
