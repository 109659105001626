import localeAntd from 'antd/es/locale/it_IT'
import messages from "./it.json"
/* const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Cerca...',
  'topBar.findPages': 'Cerca pagine...',
  'topBar.actions': 'Azioni',
  'topBar.status': 'Stato',
  'topBar.profileMenu.hello': 'Ciao',
  'topBar.profileMenu.billingPlan': 'Tipo abbonamento',
  'topBar.profileMenu.role': 'Ruolo',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Telefono',
  'topBar.profileMenu.editProfile': 'Modifica profilo',
  'topBar.profileMenu.logout': 'Esci',
} */

export default {
  locale: 'it-IT',
  localeAntd,
  messages,
}
