import axios from 'axios'
import store from 'store'
// import { notification } from 'antd'
import * as qs from "qs";
import {API_URL} from "config/global";
import keycloak from "config/keycloak";

const apiClient = axios.create({
  baseURL: API_URL,
  // timeout: 1000,
  // headers: { 'accept': 'application/json' }
})

apiClient.interceptors.request.use(async request => {
  let accessToken
  // keycloak refresh token
  try {
    const refreshed = await keycloak.updateToken(5)
    if(refreshed){
      accessToken = keycloak.token
      console.log("refreshed")
      store.set('accessToken', accessToken)
    } else {
      accessToken = store.get('accessToken')
    }
  }catch (e) {
    return keycloak.init({onLoad: 'login-required', responseMode: 'query'})
  }

  if(process.env.NODE_ENV === 'development' && document.cookie.indexOf("XDEBUG_SESSION") >=0 ){
    request.params = request.params || {}
    request.params.XDEBUG_SESSION_START='PHPSTORM'
  }

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    // request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.request.use(config => {
  config.paramsSerializer = params2 =>
    qs.stringify(params2, {
      arrayFormat: 'brackets',
      encode: false,
    })
  return config
})


/* apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
    notification.warning({
      message: `Errore ${response.status}`,
    })
  }
}) */

export default apiClient
