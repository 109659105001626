import Keycloak from 'keycloak-js'

const keycloak = Keycloak({
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
})

// console.log("keycloak", "constructor")

export default keycloak
