import React from 'react' // , {useEffect, useState}
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import { FormattedMessage } from 'react-intl'

class EmailForm extends React.Component {
  render() {
    return (
      <div>
        <div className="fg fg-2e">
          <Field className={'span2'} label="global.recipient" obb name="recipient" />
          <Field className={'span2'} label="global.subject" obb name="subject" />
          <Field label="Cc" name="cc" />
          <Field label="Bcc" name="bcc" />
          <Field
            className="span2 mb-3"
            label={''}
            autoSize={{ minRows: 15 }}
            component="textarea"
            name="body"
          />
        </div>
      </div>
    )
  }
}

export default withModalForm({
  apiResource: '/api/emails',
  title: <FormattedMessage id={'global.new_message'} />,
  width: 600,
})(EmailForm)
