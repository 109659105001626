import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import {Creators} from './redux/modals'
import Router from './router'
import * as serviceWorker from './serviceWorker'
import {getDebugSource, gotoEditor, InspectorWrapper} from './devInspector'

// middlewared
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
   middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

// const getItemCodes = (type) => apiClient.get("api/item_codes",{params: {type}}).then(res => res.data['hydra:member']);

ReactDOM.render(
  <Provider store={store}>
    <InspectorWrapper
      // props docs see:
      // https://github.com/zthxxx/react-dev-inspector#inspector-component-props
      keys={['control', 'shift', 'command', 'c']}
      disableLaunchEditor
      onHoverElement={(params) => { console.log('onHoverElement', params)}}
      onClickElement={(params) => {
        // const getSourcePaths =
        console.log(params.fiber)
        const sourceMap = getDebugSource(params.fiber,[])
        store.dispatch(Creators.open("openEditor",{
          sourceMap,
          onClick: (selected)=> {
            gotoEditor({
              lineNumber: selected.lineNumber,
              columnNumber: selected.columnNumber,
              relativePath: selected.relativePath,
              absolutePath: selected.fileName
            })
          }
        }))
      }}
    >
      <Localization>
        <Router history={history} />
      </Localization>
    </InspectorWrapper>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
