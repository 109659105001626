import React, {useMemo} from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'
import {snakeCase} from "lodash";
import CampaignsField from '../CampaignsPage/CampaingsField'
import ProductsField from '../ProductsPage/ProductsField'
import DocumentsField from '../DocumentsPage/DocumentsField'
import EventsField from '../CalendarPage/EventsField'
import EmailsField from '../EmailsPage/EmailsField'
import formSections from './formSections'

const { TabPane } = Tabs

const componentsMap = {
  date: Datepicker,
  itemCode: ItemCodeSelect,
  textarea: 'textarea',
}

const fieldTypes = {
  captureDate: 'date',
  updatedAt: 'date',
  createdAt: 'date',
  leadStatus: 'itemCode',
  leadSource: 'itemCode',
  leadRating: 'itemCode',
  leadIndustry: 'itemCode',
  description: 'textarea',
}

const fieldProps = {
  leadStatus: {type: 'lead_status', label: 'global.status'},
  leadSource: {type: 'lead_source', label: 'global.source'},
  leadRating: {type: 'lead_rating', label: 'global.rating'},
  leadIndustry: {type: 'lead_industry', label: 'global.industry'},
  createdAt: {disabled: true},
  updatedAt: {disabled: true},
  description: {className: 'span2', autoSize: true},
  addrStreet: {className: 'span2', label: 'global.street'},
  addrCity: {label: 'global.city'},
  addrState: {label: 'global.state'},
  addrZip: {label: 'global.zip_code'},
  addrCountry: {label: 'global.country'},
  skype: {labelStr: 'Skype'},
  facebook: {labelStr: 'Facebook'},
  twitter: {labelStr: 'Twitter'},
  fax: {labelStr: 'Fax'},
}

const FormBuilder = ({sections}) => {
  return useMemo(()=> {
    return (
      <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
        {sections.map((section) => {
          return (
            <>
              <b className={'span2'}>
                <FormattedMessage id={`${section.title}`} />
              </b>
              {section.fields.map((field) => {
                const snakeName = snakeCase(field);
                return (
                  <Field
                    key={field}
                    name={field}
                    label={fieldProps?.[field]?.labelStr ? null :  `global.${snakeName}`}
                    component={fieldTypes[field] ? componentsMap[fieldTypes[field]] : undefined}
                    {...(fieldProps[field] || {})}
                  />
                )
              })}
            </>
          )
        })}
      </div>
    )
  },[sections])
}

class LeadForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'leads.details'}>
          <FormBuilder sections={formSections} />
        </TabPane>
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.events'} />} key={'leads.events'}>
            <EventsField lead={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.campaigns'} />} key={'leads.campaigns'}>
            <CampaignsField lead={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane
            tab={<FormattedMessage id={'global.products_services'} />}
            key={'leads.products'}
          >
            <ProductsField lead={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.documents'} />} key={'leads.documents'}>
            <DocumentsField lead={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'Emails'} />} key={'leads.emails'}>
            <EmailsField
              leads={this.props.form.values['@id']}
              email={this.props.form.values.email}
            />
          </TabPane>
        )}
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/leads',
  title: <FormattedMessage id={'global.edit_lead'} />,
  width: 1200,
})(LeadForm)
