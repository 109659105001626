export function getDimensions() {
  const w = window
  const d = document
  const e = d.documentElement
  const g = d.getElementsByTagName('body')[0]
  const x = w.innerWidth || e.clientWidth || g.clientWidth
  const y = w.innerHeight || e.clientHeight || g.clientHeight
  return { x, y }
}

export function chunkArray(myArray, chunk_size) {
  let index = 0
  const arrayLength = myArray.length
  const tempArray = []

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size)
    // Do something if you want with the group
    tempArray.push(myChunk)
  }

  return tempArray
}

export function timeDifference(current, previous) {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  const elapsed = current - previous

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)} sec fa`
  }

  if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} min fa`
  }

  if (elapsed < msPerDay) {
    return `${Math.round(elapsed / msPerHour)} ore fa`
  }

  if (elapsed < msPerMonth) {
    return `${Math.round(elapsed / msPerDay)} giorni fa`
  }

  if (elapsed < msPerYear) {
    return `${Math.round(elapsed / msPerMonth)} mesi fa`
  }

  return `${Math.round(elapsed / msPerYear)} anni fa`
}

export function relativeTime(previous) {
  return timeDifference(new Date().getTime(), previous)
}

export function getWindowHeight() {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
}
