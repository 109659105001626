import React from 'react'
import { Input } from 'antd'
import {FastField, Field as FkField} from 'formik'
import fieldProps from "components/platform/form/inputs/fieldProps";
import Select from "components/platform/form/inputs/Select";
import {FormattedMessage} from "react-intl";

const PInput = fieldProps(Input)
const PSelect = fieldProps(Select)
const PTextArea = fieldProps(Input.TextArea)

const getDefaultInput = name => {
  switch (name) {
    case 'textarea':
      return PTextArea
    case 'select':
      return PSelect
    default:
      return PInput
  }
}

class LayoutField extends React.Component {
  render() {
    const {
      className = '',
      labelStyle = null,
      label,
      obb,
      appendItem,
      children,
      append,
      labelStr,
    } = this.props

    const labelfinal = label && label !== '' ? <FormattedMessage id={label} /> : labelStr

    return (
      <div className={`form-group ${className}`}>
        {labelfinal !== null && (
          <div className="fg-label" style={labelStyle}>
            {labelfinal}
            {obb ? <span style={{color: 'red'}}>*</span> : ''}
          </div>
        )}
        <div>
          {children}
          {append}
          {appendItem && appendItem()}
        </div>
      </div>
    )
  }
}

export default class Field extends React.Component {
  state = {
    hasError: false,
  }

  render() {
    const {
      name,
      // size = 'sm',
      component = '',
      // layout props
      className = '',
      labelStyle = null,
      label,
      obb,
      appendItem,
      children,
      append,
      dynamic,
      labelStr,
      //
      ...rest
    } = this.props

    const { hasError } = this.state
    const Component = typeof component === 'string' ? getDefaultInput(component) : component
    if (hasError) {
      // You can render any custom fallback UI
      return <div className="alert alert-danger">Something went wrong. </div>
    }
    let useDynamicField = dynamic
    // setValues depends of other values so this require a dynamic field
    if (rest.setValues) {
      useDynamicField = true
    }
    const FieldComponent = useDynamicField ? FkField : FastField
    return (
      <LayoutField
        {...{
          className,
          labelStyle,
          label,
          labelStr,
          obb,
          appendItem,
          children,
          append,
        }}
      >
        <FieldComponent name={name} component={Component} {...rest} />
      </LayoutField>
    )
  }
}
