import React from "react";
import Select from "../../../components/platform/form/inputs/Select";
import Field from "../../../components/platform/form/Field";
import {withModalForm} from "../../../components/platform/hocs/Form";
import {LexicalEditor} from "../../../components/platform/form/inputs/lexical/LexicalEditor";
import Switch from "../../../components/platform/form/inputs/Switch";
import AsyncSelect from "../../../components/platform/form/inputs/AsyncSelect";
// import rangeParser from 'parse-numeric-range'


// function to get id from youtube url in all formats
/* function youtubeParser(url: any){
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).* /;
  const match = url.match(regExp);
  return (match&&match[7].length===11)? match[7] : false;
} */

const Form = () => {
  /* const form = useFormikContext<any>()
  const learnItem = form.values


  let preview = null

  if(learnItem.kind === 'youtube') {
    const _onReady = (event: any) => {
      // access to player in all event handlers via event.target
      setTimeout(() => {event.target.pauseVideo();}, 10000);
    }
    const _onChange = (event: any) => {
      console.log(event);
    }
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        modestbranding: 1
      },
    };
    preview =  learnItem.source && (
      <div className="p-3">
        <YouTube videoId={youtubeParser(learnItem.source)} opts={opts} onStateChange={_onChange} onReady={_onReady} />
      </div>
    )
  } */


  return (
    <div className="grid gap-5 grid-cols-2">
      <Field
        name="kind"
        labelStr="Tipo"
        component={Select}
        style={{width: 200}}
        items={[{ id: "youtube", label: "Youtube"}, { id: "post", label: "Post"}, { id: "link", label: "Link"}]}
      />
      <Field name="isPublic" labelStr="Post pubblico" component={Switch} />
      <Field name="title" className="span2" labelStr="Title" />
      <Field
        name="metadata.lexical"
        className="col-span-2"
        labelStr=""
        component={LexicalEditor}
      />
      <Field
        name="tags"
        labelStr="Tags"
        component={Select}
        mode="tags"
        style={{width: "100%"}}
        className="col-span-2"
      />
      <Field
        name="sharedUsers"
        labelStr="Condiviso con"
        component={AsyncSelect}
        mode="multiple"
        valueKey="@id"
        valueLabel="fullName"
        source="/api/users"
        style={{width: "100%"}}
        className="span2"
      />
    </div>
  )
}

export const PostForm = withModalForm({
  title: "Post",
  apiResource: "/api/posts",
  width: 980
} as any)(Form)
