import * as React from 'react'
import { Select, Skeleton } from 'antd'
import { isObject } from 'formik'
import visionClient from 'services/axios'
import fieldProps from './fieldProps'

const { Option } = Select

const isIrisResource = (value) => isObject(value) && value['@id']

class AsyncSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.useIris && isIrisResource(props.value) ? [props.value] : [],
      loading: true,
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    const { parms, source } = this.props
    return visionClient
      .get(source, {
        params: parms,
        pagination: false,
      })
      .then((res) => this.setState({ data: res.data['hydra:member'], loading: false }))
  }

  render() {
    const {
      value,
      onChange,
      valueKey = 'id',
      valueLabel = 'label',
      mode,
      style,
      placeholder,
      useIris = false,
    } = this.props

    if (this.state.loading) return <Skeleton.Input active />

    // supporto valore iris
    let currentValue = value
    if (useIris && isIrisResource(currentValue)) {
      currentValue = currentValue['@id']
    }

    const options = this.state.data.map((d) => (
      <Option value={d[valueKey]} key={d[valueKey]} item={d}>
        {d[valueLabel]}
      </Option>
    ))
    return (
      <Select
        showSearch
        style={style}
        placeholder={placeholder}
        allowClear
        value={currentValue}
        onChange={onChange}
        mode={mode}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    )
  }
}

export default fieldProps(AsyncSelect)
