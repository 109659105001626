import {PreloaderInterface} from "@logicpanel/ui";
import apiClient from "./axios";

class Preload implements PreloaderInterface{
  async getItemCodes (){
    return apiClient.get('api/item_codes',{params: {pagination: false}}).then((res) => { return res.data['hydra:member'] })
  }

  async getPlatformConfigs (){
    return apiClient.get('api/platform_configs',{params: {pagination: false}}).then((res) => { return res.data['hydra:member'] })
  }

  async getData(){
    const itemCodes = await this.getItemCodes()
    const platformConf = await this.getPlatformConfigs()

    const platformConfigs = platformConf.reduce((acc:any, item:any) => {
      acc[item.identifier] = item
      return acc;
    },{});

    const itemCodesByType = itemCodes.reduce((acc:any, item:any) => {
      acc[item.type] = acc[item.type] || [];
      acc[item.type].push(item);
      return acc;
    },{});

    const itemCodesById = itemCodes.reduce((acc:any, item:any) => {
      acc[item['@id']] = item;
      return acc;
    },{})

    // save data to local storage
    // localStorage.setItem('itemCodes', JSON.stringify(itemCodes))
    // localStorage.setItem('platformConfigs', JSON.stringify(platformConfigs))
    return {
      itemCodes,
      platformConfigs,
      itemCodesByType,
      itemCodesById
    }
  }
  /*
  getItemCodes({tipo: ['ad_category','cv_contratto','impegno_inquadr','settori'], tenant,pagination:false, lang}).then((r:any)=>{
      const itemCodes = r.reduce((acc:any, item:any) => {
        acc[item.tipo] = acc[item.tipo] || [];
        acc[item.tipo].push(item);
        return acc;
      },{});
      const itemCodesById = r.reduce((acc:any, item:any) => {
        acc[item.id] = item;
        return acc;
      },{});
    });
  * */
}

export default new Preload()
