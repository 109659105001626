import React from 'react'
import { Checkbox } from 'antd'
// import parse from 'date-fns/parse'
import { FormattedMessage } from 'react-intl'
import Field from 'components/platform/form/Field'
import Select from 'components/platform/form/inputs/Select'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import Timepicker from '../../../components/platform/form/inputs/Timepicker'
import { withModalForm } from '../../../components/platform/hocs/Form'

const calcoloDate = data => {
  let start
  if (data.date === null) {
    start = null
  } else if (data.date === undefined) {
    start = undefined
  } else if (data.startTime) {
    start = `${data.date} ${data.startTime}`
  } else {
    start = `${data.date}`
  }
  let end
  if (data.date === null) {
    end = null
  } else if (data.date === undefined) {
    end = undefined
  } else if (data.endTime) {
    end = `${data.date} ${data.endTime}`
  } else {
    end = null // `${data.date}`
  }

  return {
    ...data,
    start,
    end,
    startTime: data.startTime,
    endTime: data.endTime,
    date: data.date,
  }
}

class EventForm extends React.Component {
  render() {
    const { form } = this.props
    const { user } = form.values

    return (
      <div>
        <div className="fg fg-2e">
          <Field label={'global.title'} obb className={'span2'} name={'title'} />
          <Field
            label={'global.description'}
            className={'span2'}
            component={'textarea'}
            autosize
            name={'description'}
          />
          <Field
            label={'global.type_activity'}
            component={Select}
            items={[
              { id: 'call', label: <FormattedMessage id={'global.phone_call'} /> },
              { id: 'person', label: <FormattedMessage id={'global.appointment'} /> },
              { id: 'reminder', label: <FormattedMessage id={'global.reminder'} /> },
            ]}
            name={'eventType'}
          />
          <div />
          <div>
            <Field
              label={'global.day'}
              onChange={val => form.setValues(calcoloDate({ ...form.values, date: val }))}
              className={''}
              component={Datepicker}
              name={'date'}
            />
            <div>
              <Checkbox
                checked={form.values.allDay > 0}
                onChange={({ target }) => form.setFieldValue('allDay', target.checked)}
                style={{ marginTop: 15 }}
              >
                <FormattedMessage id={'global.all_day'} />
              </Checkbox>
            </div>
            <div>
              <Checkbox
                checked={form.values.completed > 0}
                onChange={({ target }) => form.setFieldValue('completed', target.checked)}
                style={{ marginTop: 4 }}
              >
                <FormattedMessage id={'global.completed'} />
              </Checkbox>
            </div>
          </div>
          {!form.values.allDay && (
            <table>
              <tbody>
                <tr>
                  <td style={{ width: 100 }}>
                    <Field
                      label={'global.from'}
                      className={''}
                      placeholder={'hh:mm'}
                      style={{ width: 85 }}
                      onChange={val =>
                        form.setValues(calcoloDate({ ...form.values, startTime: val }))
                      }
                      component={Timepicker}
                      name={'startTime'}
                    />
                  </td>
                  <td>
                    <Field
                      label={'global.to'}
                      className={''}
                      style={{ width: 85 }}
                      onChange={val =>
                        form.setValues(calcoloDate({ ...form.values, endTime: val }))
                      }
                      placeholder={'hh:mm'}
                      component={Timepicker}
                      name={'endTime'}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {user && (
            <div className={'span2 text-right'}>
              <FormattedMessage id={'global.created_by'} />:{' '}
              {user.firstName ? `${user.firstName} ${user.lastName}` : user}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withModalForm({
  apiResource: 'api/events',
  title: <FormattedMessage id={'global.event_update'} />,
  width: 600,
})(EventForm)
