import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import english from './locales/en-US'
import italian from './locales/it-IT'
import spanish from './locales/es-ES'


const locales = {
  'en-US': english,
  'it-IT': italian,
  'es-ES': spanish,
}



const Localization = ({ children, user: { locale } }) => {
  const currentLocale = locales[locale]
  return (
    <ConfigProvider locale={currentLocale.localeAntd}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}


const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(Localization)
