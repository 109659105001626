import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import JAutocomplete from 'components/platform/form/inputs/JAutocomplete'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'
import { IssuePreview } from '../../jira/issues/components/IssuePreview'

const { TabPane } = Tabs

class TaskForm extends React.Component {
  render() {
    return (
      <div className="grid grid-cols-3">
        <div className="col-span-2">
          <Tabs tabPosition="left">
            <TabPane tab={<FormattedMessage id={'global.details'} />} key={'tasks.details'}>
              <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
                <b className={'span2'}>
                  <FormattedMessage id={'global.basic_information'} />
                </b>
                <Field label="global.tasks_title" obb name="title" />
                <Field label="global.due_date" name="endDate" component={Datepicker} />
                <b className="span2 mt-2">
                  <FormattedMessage id={'global.description'} />
                </b>
                <Field
                  className={'span2'}
                  label={'global.description'}
                  autoSize={{ minRows: 1 }}
                  component="textarea"
                  name="description"
                />
                <b className="span2 mt-2">
                  <FormattedMessage id={'global.additional_information'} />
                </b>
                <Field
                  label="global.tasks_assigned_user"
                  name="userAssign"
                  useIris
                  valueKey={'@id'}
                  valueLabel={'fullName'}
                  source={'/api/users'}
                  component={JAutocomplete}
                  getLabel={i => `${i.fullName}`}
                  filter={'fullName'}
                />
                <Field
                  label="global.account"
                  name="account"
                  useIris
                  valueKey={'@id'}
                  valueLabel={'name'}
                  source={'/api/accounts'}
                  component={JAutocomplete}
                  getLabel={i => `${i.name}`}
                  filter={'name'}
                />
                <Field
                  label="global.priority"
                  name="taskPriority"
                  type={'task_priority'}
                  component={ItemCodeSelect}
                />
                <Field
                  label="global.status"
                  name="taskStatus"
                  type={'task_status'}
                  component={ItemCodeSelect}
                />
                <Field
                  label="global.contact"
                  name="contact"
                  useIris
                  valueKey={'@id'}
                  valueLabel={'fullName'}
                  source={'/api/contacts'}
                  component={JAutocomplete}
                  getLabel={i => `${i.fullName}`}
                  filter={'fullName'}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
        <div>
          <IssuePreview issueKey={this.props.form.values.metadata?.key} />
        </div>
      </div>

    )
  }
}

export default withModalForm({
  apiResource: '/api/tasks',
  title: <FormattedMessage id={'global.edit_tasks'} />,
  width: 1280,
})(TaskForm)
