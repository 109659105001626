import React from "react";

export function on(event,cb){
  try{
    window.socket.on(event,cb)
  }catch (e){
    console.log(e);
  }
}

export function off(event,cb){
  try{
    window.socket.off(event,cb)
  }catch (e){
    console.log(e);
  }
}

export function emit(event,data){
  try{
    window.socket.emit(event, data)
  }catch (e){
    console.log(e);
  }
}


const withSocket = () => (Component) => (props)=> (
  <Component
    {...props}
    socket={{
      emit,
      on,
      off
    }}
  />
)

export default withSocket
