/* global $ */
import React from "react";
import Dialog from "../Dialog";
import apiClient from "../../../services/axios";
import {API_URL} from "../../../config/global";

/* GOOGLE DOC SUPPORT
export default class FilePreview extends React.Component{

    state ={
        loading:true,
        urlPreview:""
    }

    refreshOnError (){
        this.timer = setInterval(() => {
            console.log('reload the iframe')
            $(this.iframe).attr("src",function ( i, val ) { return val; });
        }, 4000);
    }

    loadPreview(){
        const {src} = this.props
        if(src){
            this.refreshOnError (src)
            $(this.iframe).load(() =>{
                console.log('laoded the iframe')
                this.setState({loading:false})
                if(this.timer){
                    clearInterval(this.timer);
                    this.timer = null;
                }
            });
        }
    }

    componentDidMount(){
        this.getUrl();
    }

    componentWillUnmount(){
        if(this.timer){
            clearInterval(this.timer);
        }
    }

    getUrl(){
        const {src, filename} = this.props
        $.get( "/it/candidati/preview-url", {src, filename},(urlPreview) => {
            this.setState({urlPreview},() =>  this.loadPreview() )
        });
    }

    render(){
        const {loading,urlPreview} = this.state
        return (
            <div>
                { loading && <div>Loading...</div> }
                <iframe  ref={(iframe)=>this.iframe=iframe} src={urlPreview} style={{border:0,width:"100%",minHeight:800}}  />
            </div>

        )
    }
}
*/

export default class FilePreview extends React.Component{

  state ={
    loading:true,
  }

  componentDidMount(){
    this.loadPreview();
  }

  componentWillUnmount(){
    if(this.timer){
      clearInterval(this.timer);
    }
  }

  _onLoad = () => {
    if(this.isinit){
      console.log('Iframe loaded')
      this.setState({loading:false})
      if(this.timer){
        clearInterval(this.timer);
        this.timer = null;
      }
    }
  }

  loadPreview = async () => {
    const src = await this.getUrl()
    this.refreshOnError()
    $(this.iframe).attr("src", () => src)
    this.isinit = true;
  }

  getUrl = async () => {
    const {src} = this.props
    const signedUrl = src.indexOf("uploads/cloud") !== -1
    if(!signedUrl) return API_URL+src
    const {data} = await apiClient.get(src,{params:{response: "signed-url"}})
    return data.url
  }

  refreshOnError (){
    this.timer = setInterval(() => {
      console.log('reload the Iframe')
      $(this.iframe).attr("src", ( i, val ) => val);
    }, 4000);
  }

  render(){
    const {loading} = this.state
    return (
      <div>
        { loading && <div>Loading...</div> }
        <iframe
          title={"File preview"}
          ref={(iframe)=>{this.iframe=iframe}}
          src={"about:blank"}
          onLoad={this._onLoad}
          style={{border:0,width:"100%",minHeight:800}}
        />
      </div>

    )
  }
}

export const DialogFilePreview = (props) => (
  <Dialog modal width="95%" title={props.filename} opened onClose={props.onClose}>
    <FilePreview src={props.src} filename={props.filename} />
  </Dialog>
)
