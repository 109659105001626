import {createActions,createReducer } from 'reduxsauce'
import  update from 'immutability-helper';

const defaultForm = {}
const initialState  = {
    items: [ ],
}

const initStateForm = (options) => ({ ...defaultForm , ...options  })

const { Types, Creators } = createActions({
    open:['key','options'],
    openSingleton:['key','options'],
    close:['key'],
},{
    prefix:"MODAL_"
})

const openReducer = (state = initialState, {key,options} ) => update(state , {items: {  $push:  [ initStateForm({ _type:key ,...options}) ] } } )

const openSingletonReducer = (state = initialState, {key,options} ) => {
  const position = state.items.findIndex( (item ) => item._type === key )
  if(position < 0) {
    return  update(state , {items: {  $push:  [ initStateForm({ _type:key ,...options}) ] } } )
  }
  return  update(state , {items: { [position] : {  $set:  initStateForm({ _type:key ,...options}) } } } )
}

const removeFormReducer = (state = initialState, {key}) =>  update(state , {items: { $splice: [[key, 1]] }} )
// update(state , {items: {  $unset: [key]  }} )

const reducers = createReducer(initialState, {
    [Types.OPEN] : openReducer,
    [Types.OPEN_SINGLETON] : openSingletonReducer,
    [Types.CLOSE] : removeFormReducer
});

export {Creators,reducers}
