import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { renderDate, printName } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.account_name'} />,
    dataIndex: 'name',
    sorter: true,
    width: 220,
  },
  {
    title: <FormattedMessage id={'global.website'} />,
    dataIndex: 'website',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.type'} />,
    dataIndex: 'accountType',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.industry'} />,
    dataIndex: 'accountIndustry',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.rating'} />,
    dataIndex: 'accountRating',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.account_number'} />,
    dataIndex: 'accountNumber',
    sorter: true,
    className: 'text-center',
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.annual_revenue'} />,
    dataIndex: 'annualRevenue',
    sorter: true,
    className: 'text-center',
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.employees'} />,
    dataIndex: 'employees',
    sorter: true,
    className: 'text-center',
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.phone'} />,
    dataIndex: 'phone',
    sorter: true,
    className: 'text-center',
    width: 170,
  },
  {
    title: <FormattedMessage id={'global.email'} />,
    dataIndex: 'email',
    sorter: true,
    width: 220,
  },
  {
    title: <FormattedMessage id={'global.secondary_email'} />,
    dataIndex: 'secondaryEmail',
    sorter: true,
    width: 220,
  },
  {
    title: 'Fax',
    dataIndex: 'fax',
    sorter: true,
    width: 120,
  },
  {
    title: 'Skype ID',
    dataIndex: 'skypeId',
    sorter: true,
    width: 120,
  },
  {
    title: <FormattedMessage id={'global.street'} />,
    dataIndex: 'street',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.city'} />,
    dataIndex: 'city',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.state'} />,
    dataIndex: 'state',
    sorter: true,
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.country'} />,
    dataIndex: 'country',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.zip_code'} />,
    dataIndex: 'zipCode',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.tax_number'} />,
    dataIndex: 'taxNumber',
    sorter: true,
    width: 170,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const AccountsField = ({ campaign, contact }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Account'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.accounts'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_account'} />}
            onClick={() => {
              modals.open(MODAL_NAME.account, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: { campaigns: [campaign], contact },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/accounts'}
    modalKey={MODAL_NAME.account}
    filters={{ campaigns: campaign, contact }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default AccountsField
