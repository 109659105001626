import React from 'react'
import ReactDOM from 'react-dom'
import { Spinner } from 'reactstrap'
import { Modal, Button, notification, Alert, Tooltip } from 'antd'
import {Formik, isFunction} from 'formik'
import { compose } from 'redux'
import './style.scss'
import Dialog from 'components/platform/Dialog'
import apiClient from 'services/axios'
import { FormattedMessage } from 'react-intl'
import withSocket from '../withSocket'
import differences from '../../utils/differences'

const isEmptyValue = value => {
  if (value === null || value === undefined) {
    return true
  }
  if (Array.isArray(value)) {
    return value.length === 0
  }
  if (typeof value === 'object') {
    return Object.keys(value).length === 0
  }
  if(typeof value === 'string') {
    return value.trim() === ''
  }
  if(value === 0) {
    return true
  }

  return false
}

export const resourceClient = {
  getItems: (url, config) => apiClient.get(`${url}`, config),
  getItem: (url, id) => apiClient.get(`${url}/${id}`, { params: {} }),
  deleteItem: (url, id) => apiClient.delete(`${url}/${id}`, { params: {} }),
  updateItem: (url, id, data) => apiClient.put(`${url}/${id}`, data),
  patchItem: (url, id, data) =>
    apiClient.patch(`${url}/${id}`, data, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }),
  createItem: (url, data) => apiClient.post(`${url}`, data),
  createItemMultipart: (url, data) => {
    const fmData = new FormData()
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }
    const formProps = {}
    Object.keys(data).forEach(key => {
      if (data[key] !== undefined) {
        if (data[key] instanceof File) {
          fmData.append(key, data[key])
        } else {
          formProps[key] = data[key]
        }
      }
    })
    fmData.append('formProps', JSON.stringify(formProps))
    return apiClient.post(`${url}`, fmData, config)
  },
  saveItem: (url, { id, ...data }, { withPatch, multipart }) => {
    if (id) {
      return withPatch
        ? resourceClient.patchItem(url, id, data)
        : resourceClient.updateItem(url, id, data)
    }
    if (multipart) {
      return resourceClient.createItemMultipart(url, data)
    }
    return resourceClient.createItem(url, data)
  },
}

const OnlineCounter = () => (
  <Tooltip title={'Il form è stato aperto'}>
    {<i style={{ cursor: 'pointer' }} className={'fa fa-circle text-warning'} />}
  </Tooltip>
)
const ExternalChanges = () => (
  <Tooltip title={'Modificato esternamente'}>
    {<i style={{ cursor: 'pointer' }} className={'fa fa-exclamation-triangle text-danger ml-2'} />}
  </Tooltip>
)

const { confirm } = Modal
export function showDeleteConfirm(title, ok) {
  confirm({
    title,
    content: 'Si per continuare',
    okText: 'SI',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      ok()
    },
    onCancel() {
      console.log('Cancel')
    },
  })
}
const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message,
    placement: 'bottomRight',
    // description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
  })
}
export const Message = {
  show: ({ data }) => {
    console.log(data)
    openNotificationWithIcon(data.error ? 'error' : 'success', data.message)
  },
}

/*
class ApiResource {

  async saveItem({apiResource, withPatch, data}){
    return resourceClient.saveItem(
      apiResource,
      withPatch,
      data,
    )
  }

  async deleteItem({apiResource, id}){
    return resourceClient.deleteItem(apiResource, id)
  }

  async getItem({apiResource, id, parms}){
    const res =  resourceClient.getItem(apiResource, id, parms)
    return {
      item: res.data,
      rest: {}
    }
  }
}
*/
export function withRemoteActions({
  source: baseUrl,
  apiResource = null,
  setItemOnSave = true,
  savingFn,
  multipart,
  apiMethod,
}) {
  return Component => {
    class RemoteActionComponent extends React.Component {
      state = {
        item: null,
        itemHash: null,
        loadingItem: true,
        savingItem: false,
        deletingItem: false,
        responseItem: null,
        errors: null,
      }

      _getDefaultItem = async () => {
        const { defaultItem } = this.props
        return {
          data: defaultItem || {},
        }
      }

      _finalGetItem = async id => {
        const { parms } = this.props

        let res
        let item
        let rest
        if (apiResource) {
          res =
            !isEmptyValue(id)
              ? await resourceClient.getItem(apiResource, id, parms)
              : await this._getDefaultItem(apiResource, id, parms)
          console.log('apiResource', res)
          item = res.data
          rest = {}
        } else {
          res = await apiClient.get(`${baseUrl}/${id}`, { params: { ...parms } })
          const { item: respItem, ...other } = res.data
          item = respItem
          rest = other
        }

        this.setState({
          item,
          responseItem: rest,
          loadingItem: false,
          savingItem: false,
          itemHash: null,
        })
      }

      _getItem = async id => {
        await this._setState({ loadingItem: true })
        return this._finalGetItem(id)
      }

      _finalSave = async (item, callback) => {
        const { onRemoteSaved } = this.props
        const savingItem = savingFn && isFunction(savingFn) ? savingFn({ data: item }) : item

        console.log('savingItem', apiMethod, savingItem)

        try {
          const response = await resourceClient.saveItem(
            apiResource,
            savingItem,
            {
              withPatch: apiMethod === 'patch',
              multipart,
            }
          )
          Message.show({ data: { error: false, message: 'Salvato correttamente' } })

          const newState = setItemOnSave
            ? {
                item: { ...this.state.item, ...response.data },
                savingItem: false,
                itemHash: null,
              }
            : {
                savingItem: false,
                itemHash: null,
              }

          await this._setState(newState)

          if (callback) callback(response)
          if (onRemoteSaved) onRemoteSaved(response)
        } catch (error) {
          // console.log('eerors', error.response)

          let message = 'Errore server, riprovare'
          if (error.response.status === 400) {
            message = 'Compila i dati correttamente'
          }

          Message.show({ data: { error: true, message } })
          await this._setState({
            savingItem: false,
            errors: error.response.data['hydra:description'],
          })
        }

        // console.log('eerors', e)
        // this.setState({ savingItem: false, errors: e.response.data.errors })
      }

      _setState = state =>
        new Promise(resolve => {
          this.setState(state, resolve)
        })

      _save = async (item, callback = null) => {
        console.log('saving', item)
        await this._setState({ savingItem: true, errors: null })
        await this._finalSave(item, callback)
      }

      _confirmDelete = (id, callback) => {
        const { onRemoteDeleted } = this.props

        showDeleteConfirm('Sei sicuro di voler eleminare questo record?', async () => {
          await this._setState({ deletingItem: true })
          const response = await resourceClient.deleteItem(apiResource, id)

          this.setState({ deletingItem: false })
          if (callback) callback()
          if (onRemoteDeleted) {
            response.id = id
            if (apiResource) response.iris = `${apiResource}/${id}`
            console.log(response)
            onRemoteDeleted(response)
          }

          if (apiResource) {
            Message.show({ data: { error: false, message: 'Eliminato correttamente' } })
          } else {
            Message.show(response)
          }
        })
      }

      _onItemChange = item => this.setState({ item })

      render() {
        const {
          loadingItem,
          item,
          responseItem,
          savingItem,
          deletingItem,
          itemHash,
          errors,
        } = this.state
        return (
          <Component
            remote={{
              loadItem: this._getItem,
              saveItem: this._save,
              deleteItem: this._confirmDelete,
              onItemChange: this._onItemChange,
              loadingItem,
              itemHash,
              savingItem,
              deletingItem,
              item,
              errors,
              responseItem,
            }}
            {...this.props}
          />
        )
      }
    }
    return RemoteActionComponent
  }
}

const Div = props => <div>{props.children}</div>

export const withModalForm = ({
  source,
  apiResource,
  title = '',
  width = '95%',
  bodyStyle = null,
  dialogClass = undefined,
  leftButtons: LeftButtons,
  buttons = {},
  apiMethod = 'patch',
  savingFn,
  multipart = false,
}) => FormWrapped => {
  class ModalForm extends React.Component {
    isPage = false

    roomName = null

    constructor(props) {
      super(props)
      if (this.props.match && this.props.match.params.itemId) {
        this.isPage = true
      }
      this.state = {
        width,
        clientCounter: 0,
        externalChanges: false,
      }
    }

    componentDidMount() {
      const id = this._getId()
      this.props.remote.loadItem(id)

      if (id && !isEmptyValue(id)) {
        this.roomName = `modalform_${this.props._type}_${id}`
        this.props.socket.on(`${this.roomName}:clients_counter`, this._onSocketCounter)
        this.props.socket.on(`${this.roomName}:events`, this._onSocketEvents)
        this.props.socket.on('connect', this._joinRoom)
        this._joinRoom()
      }
    }

    componentWillUnmount() {
      if (this.roomName) {
        this.props.socket.off(`${this.roomName}:clients_counter`, this._onSocketCounter)
        this.props.socket.off(`${this.roomName}:events`, this._onSocketEvents)
        this.props.socket.off('connect', this._joinRoom)
        this.props.socket.emit('leave', {
          room: this.roomName,
        })
      }
    }

    _joinRoom = () => {
      console.log('_joinRoom', this.roomName)
      this.props.socket.emit('join', {
        room: this.roomName,
      })
    }

    _onSocketEvents = event => {
      console.log('_onSocketEvents', event)
      if (event.eventType === 'api:saved') {
        this.setState({ externalChanges: true })
      }
    }

    _onSocketCounter = counter => {
      this.setState({ clientCounter: counter })
    }

    _getId = () =>
      this.props.match && this.props.match.params.itemId
        ? this.props.match.params.itemId
        : this.props.itemId

    _save = () => {
      this._saveForm()
    }

    _saveClose = () => {
      this.closeModal = true
      this._saveForm()
    }

    _saveForm = () => {
      if (this.modalForm?.saveForm) {
        this.modalForm.saveForm()
      } else {
        this.form.submitForm()
      }
    }

    _setWidth = dialogWidth => {
      this.setState({ width: dialogWidth })
    }

    render() {
      const { remote, ...rest } = this.props
      const { width: dialogWidth, clientCounter, externalChanges } = this.state
      const Container = this.isPage ? Div : Dialog

      const finalTitle = isFunction(title) ? title({ remote }) : title

      return (
        <Container
          title={finalTitle}
          header={
            <>
              {finalTitle}
              <div style={{ position: 'absolute', right: 40, top: 10 }}>
                {clientCounter > 1 && <OnlineCounter counter={clientCounter} />}
                {externalChanges && <ExternalChanges />}
              </div>
            </>
          }
          bodyStyle={bodyStyle}
          visible
          opened
          modal
          style={{ top: 20 }}
          dialogClass={dialogClass}
          onClose={() => this.props.onClose()}
          footer={[
            <div
              key={'btn-left-container'}
              style={{ verticalAlign: 'middle', display: 'inline-block', marginRight: 8 }}
              ref={btnLeftContainer => {
                this.btnLeftContainer = btnLeftContainer
              }}
            />,
            buttons.saveButton === false || remote.loadingItem ? null : (
              <Button key="submit2" onClick={this._save} type="primary" loading={remote.savingItem}>
                {/* Salva e continua */}
                <FormattedMessage id={'global.save_and_continue'} />
              </Button>
            ),
            remote.loadingItem ? null : (
              <Button
                key="submit"
                onClick={this._saveClose}
                type="primary"
                loading={remote.savingItem}
              >
                {buttons.saveText || <FormattedMessage id={'global.save'} />}
              </Button>
            ),
            buttons.deleteButton === false || remote.loadingItem || !remote.item.id ? null : (
              <Button
                key="delete"
                onClick={() => remote.deleteItem(remote.item.id, this.props.onClose)}
                type="danger"
                loading={remote.deletingItem}
              >
                {/* Elimina */}
                <FormattedMessage id={'global.delete'} />
              </Button>
            ),
            <Button tabIndex={-1} key="back" onClick={() => this.props.onClose()}>
              {/* Chiudi */}
              <FormattedMessage id={'global.close'} />
            </Button>,
          ]}
          width={dialogWidth}
        >
          {remote.loadingItem && (
            <div style={{ padding: 40, textAlign: 'center' }}>
              <Spinner color="primary" />
            </div>
          )}

          {!remote.loadingItem && (
            <Formik
              innerRef={node => {
                this.form = node
              }}
              initialValues={remote.item}
              onSubmit={(values /* , { setSubmitting } */) => {
                if (remote.item.id && apiMethod === 'patch') {
                  values = differences(values, remote.item)
                  values.id = remote.item.id
                }
                remote.saveItem(values, response => {
                  // send event
                  this.props.socket.emit('room_events', {
                    room: this.roomName,
                    eventType: 'api:saved',
                    resource: response.data,
                  })
                  if (this.closeModal) {
                    this.props.onClose()
                  } else {
                    this.form.setValues(response.data)
                  }
                })
              }}
            >
              {({ isSubmitting, ...props }) => (
                <>
                  <FormWrapped
                    form={props}
                    responseItem={remote.responseItem}
                    modalForm={{
                      setWidth: this._setWidth,
                    }}
                    modal={rest}
                    ref={modalForm => {
                      this.modalForm = modalForm
                    }}
                  />
                  {this.btnLeftContainer &&
                    LeftButtons &&
                    ReactDOM.createPortal(<LeftButtons form={props} />, this.btnLeftContainer)}
                  {remote.errors != null && (
                    <div style={{ padding: 20 }}>
                      <Alert
                        message="Errore"
                        description={<div style={{ whiteSpace: 'pre-line' }}>{remote.errors}</div>}
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                </>
              )}
            </Formik>
          )}
        </Container>
      )
    }
  }
  return compose(
    withRemoteActions({
      source,
      apiResource,
      savingFn,
      multipart,
      apiMethod,
    }),
    withSocket(),
  )(ModalForm)
}
