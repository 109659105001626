import * as React from 'react'
import { InputNumber as AntInputNumber } from 'antd'

const InputNumber = (props) => {
  const { field, form, style } = props
  return (
    <AntInputNumber
      style={style}
      name={field.name}
      value={field.value}
      onChange={(value) => form.setFieldValue(field.name, value)}
    />
  )
}

export default InputNumber
