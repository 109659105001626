import * as React from 'react'
import {Helmet} from 'react-helmet'
import { Layout } from 'antd'

export default class HorizontalLayoutPage extends React.Component {
  state = {
    opened: false,
  }

  toggle() {
    const { opened } = this.state
    this.setState({ opened: !opened })
  }

  render() {
    const { opened } = this.state
    const { content, sidebar, title } = this.props
    return (
      <div>
        <Helmet title={title} />
        <Layout style={{ minHeight: 'auto' }}>
          <div style={{ display: opened ? 'block' : 'none' }}>{sidebar && sidebar}</div>
          {content}
        </Layout>
      </div>
    )
  }
}
