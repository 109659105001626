import apiClient from 'services/axios'
import store from 'store'
import keycloak from 'config/keycloak'
// import connect from 'config/ws'

export async function login(email, password) {
  return apiClient
    .post('/auth/login', {
      username: email,
      password,
    })
    .then(response => {
      if (response) {
        const { token } = response.data
        if (token) {
          store.set('accessToken', token)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { token } = response.data
        if (token) {
          store.set('accessToken', token)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  const authenticated = await keycloak.init({ onLoad: 'login-required', responseMode: 'query' })

  if (!authenticated) {
    console.log('authenticated', authenticated)
    return false
  }

  // const userInfo =  await keycloak.loadUserInfo()
  // console.log('userInfo', userInfo)
  // this.setState({ keycloak: keycloak, authenticated: authenticated })
  // console.log('token', keycloak.token)
  // console.log('tokenParsed', keycloak.tokenParsed)

  store.set('accessToken', keycloak.token)
  return apiClient
    .get('/me')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
  /*  return {
    id: keycloak.tokenParsed.jti,
    name: keycloak.tokenParsed.name || keycloak.tokenParsed.preferred_username,
    role: ['ROLE_USER'],
    email: keycloak.tokenParsed.email,
    avatar: null,
    authorized: true,
  }
  // connect()
  return {
    id: 4,
    name: 'Francesco Pastores',
    role: ['ROLE_USER'],
    email: 'fpastore@siatass.com',
    avatar: null,
    authorized: true,
  }
 return apiClient
    .get('/api/account',{
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err)) */
}

export async function logout() {
  return keycloak
    .logout()
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}
