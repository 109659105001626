import * as React from 'react'
import { Select, Skeleton } from 'antd'
import { isObject } from 'formik'
import { usePreloaderContext } from '@logicpanel/ui'
import fieldProps from './fieldProps'

const { Option } = Select

const isIrisResource = (value) => isObject(value) && value['@id']

function ItemCodeSelect(props) {
  const {
    valueKey = '@id',
    valueLabel = 'name',
    type,

    value,
    onChange,
    mode,
    style,
    placeholder,
  } = props

  const { itemCodesByType } = usePreloaderContext()

  const data = isIrisResource(value) ? [value] : itemCodesByType[type] || []

  if (!itemCodesByType) return <Skeleton.Input active />

  // supporto valore iris
  let currentValue = value
  if (isIrisResource(currentValue)) {
    currentValue = currentValue['@id']
  }

  const options = data.map((d) => (
    <Option value={d[valueKey]} key={d[valueKey]} item={d}>
      {d[valueLabel]}
    </Option>
  ))
  return (
    <Select
      showSearch
      style={style}
      placeholder={placeholder}
      allowClear
      value={currentValue}
      onChange={onChange}
      mode={mode}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  )
}


export default fieldProps(ItemCodeSelect)

/*



* */
