import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import NavigatorGlobalScreens from 'components/platform/NavigatorGlobalScreens'
import Layout from 'layouts'
import { hot } from 'react-hot-loader'

const routes = [
  // Home
  {
    path: '/home',
    Component: lazy(() => import('pages/home')),
    exact: true,
  },

  // CRM
  {
    path: '/contacts',
    Component: lazy(() => import('pages/crm/ContactsPage')),
    exact: true,
  },
  {
    path: '/leads',
    Component: lazy(() => import('pages/crm/LeadsPage')),
    exact: true,
  },
  {
    path: '/tasks',
    Component: lazy(() => import('pages/crm/TasksPage')),
    exact: true,
  },
  {
    path: '/translate',
    Component: lazy(() => import('pages/crm/TranslationPage')),
    exact: true,
  },
  {
    path: '/calendary',
    Component: lazy(() => import('pages/crm/CalendarPage')),
    exact: true,
  },
  {
    path: '/campaigns',
    Component: lazy(() => import('pages/crm/CampaignsPage')),
    exact: true,
  },
  {
    path: '/accounts',
    Component: lazy(() => import('pages/crm/AccountsPage')),
    exact: true,
  },
  {
    path: '/deals',
    Component: lazy(() => import('pages/crm/DealsPage')),
    exact: true,
  },
  {
    path: '/quotes',
    Component: lazy(() => import('pages/crm/QuotesPage')),
    exact: true,
  },
  {
    path: '/invoices',
    Component: lazy(() => import('pages/crm/InvoicesPage')),
    exact: true,
  },
  {
    path: '/orders',
    Component: lazy(() => import('pages/crm/OrdersPage')),
    exact: true,
  },
  {
    path: '/tickets',
    Component: lazy(() => import('pages/crm/TicketsPage')),
    exact: true,
  },
  {
    path: '/service_contracts',
    Component: lazy(() => import('pages/crm/ServiceContractsPage')),
    exact: true,
  },
  {
    path: '/subscriptions',
    Component: lazy(() => import('pages/crm/SubscriptionsPage')),
    exact: true,
  },
  {
    path: '/products_services',
    Component: lazy(() => import('pages/crm/ProductsPage')),
    exact: true,
  },
  {
    path: '/vendors',
    Component: lazy(() => import('pages/crm/VendorsPage')),
    exact: true,
  },
  {
    path: '/payments',
    Component: lazy(() => import('pages/crm/PaymentsPage')),
    exact: true,
  },
  {
    path: '/documents',
    Component: lazy(() => import('pages/crm/DocumentsPage')),
    exact: true,
  },
  {
    path: '/users',
    Component: lazy(() => import('pages/crm/UsersPage')),
    exact: true,
  },
  {
    path: '/dev',
    Component: lazy(() => import('pages/dev/DevPage')),
    exact: true,
  },
  /* {
    path: '/students',
    Component: lazy(() => import('pages/patento/student')),
    exact: true,
  }, */
  {
    path: '/emails',
    Component: lazy(() => import('pages/crm/EmailsPage')),
    exact: true,
  },
  {
    path: '/projects',
    Component: lazy(() => import('pages/workspaces/projects/ProjectsPage')),
    exact: true,
  },
  // PLatform
  {
    path: '/users',
    Component: lazy(() => import('pages/platform/users')),
    exact: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/jira/issues',
    Component: lazy(() => import('pages/jira/issues/IssuePage').then(module => ({ default: module.IssuePage }))),
  },
  {
    path: '/posts',
    Component: lazy(() => import('pages/knowledge/posts/PostsPage')),
  },
  {
    path: '/learner/:id?',
    Component: lazy(() => import('pages/knowledge/posts/LearnerPage')),
  },
  {
    path: '/settings',
    Component: lazy(() => import('pages/platform/settings/SettingsPage'))
  },
  {
    path: '/demo/components',
    Component: lazy(() => import('pages/demo/components/ComponentsPage'))
  }
]

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <Switch location={location}>
                <Route exact path="/" render={() => <Redirect to="/home" />} />
                {routes.map(({ path, Component, exact }) => (
                  <Route
                    path={path}
                    key={path}
                    exact={exact}
                    render={() => {
                      return (
                        <div className={routerAnimation}>
                          <Suspense fallback={null}>
                            <Component />
                          </Suspense>
                        </div>
                      )
                    }}
                  />
                ))}
                <Redirect to="/auth/404" />
              </Switch>
            )
          }}
        />
        <NavigatorGlobalScreens />
      </Layout>
    </ConnectedRouter>
  )
}

export default process.env.NODE_ENV === 'development'
  ? connect()(hot(module)(Router))
  : connect()(Router)
