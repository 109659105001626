import React from 'react' // , {useEffect, useState}

import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import Switch from 'components/platform/form/inputs/Switch'
import TicketsField from 'pages/crm/TicketsPage/TicketsField'
import CampaignsField from 'pages/crm/CampaignsPage/CampaingsField'
import DealsField from 'pages/crm/DealsPage/DealsField'
import OrdersField from 'pages/crm/OrdersPage/OrdersField'
import InvoicesField from 'pages/crm/InvoicesPage/InvoicesField'
import QuotesField from 'pages/crm/QuotesPage/QuotesField'
import JAutocomplete from 'components/platform/form/inputs/JAutocomplete'
import { FormattedMessage } from 'react-intl'
import {connect} from "react-redux"
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'
import TasksField from "pages/crm/TasksPage/TasksField";
import { SettableTabs } from 'components/platform/Tabs/SettableTabs'
import DocumentsField from '../DocumentsPage/DocumentsField'
import EventsField from '../CalendarPage/EventsField'
import EmailsField from '../EmailsPage/EmailsField'
import messages from '../../../locales/it.json'
import tabDefinitions from './tabsDefinitions.json'

const { TabPane  } = SettableTabs

window.messagesMap = Object.keys(messages).reduce((acc, key) => {
  acc[messages[key]] = key
  return acc
}, {})

console.log('messagesMap', window.messagesMap)


class ContactForm extends React.Component {

  /*
  *   const definitions = []

      const elements = document.getElementsByClassName("ant-tabs-tab-btn")
      Array.from(elements).forEach((element,index) => {
        const ariaControls = element.getAttribute("aria-controls")
        const key = ariaControls.split("panel-", 2)[1]
        definitions.push({key, label: element.innerText, labelId: messagesMap[element.innerText], visible: true, position: index})
      });

      console.log('definitions',definitions)
  * */

  render() {
    return (
      <SettableTabs
        tabPosition="left"
        definitions={tabDefinitions}
        settingsName="contactTabs"
      >
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'contacts.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.first_name" obb name="firstName" />
            <Field label="global.last_name" obb name="lastName" />
            <Field label="global.use_profile_image" component={Switch} name="useProfileImage" />
            <Field label="global.phone" name="phone" />
            <Field label="global.mobile" name="mobile" />
            <Field
              label="global.status"
              name="contactStatus"
              type={'contact_status'}
              component={ItemCodeSelect}
            />
            <Field label="global.email" name="email" />
            <Field
              label="global.account"
              obb
              component={JAutocomplete}
              source={'/api/accounts'}
              name="account"
              getLabel={i => `${i.name}`}
              useIris
              valueKey={'@id'}
              filter={'name'}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.additional_information'} />
            </b>
            <Field label="Fax" name="fax" />
            <Field
              label="global.source"
              name="contactSource"
              type={'contact_source'}
              component={ItemCodeSelect}
            />
            <Field label="global.job_title" name="jobTitle" />
            <Field label="global.assistant_name" name="assistantName" />
            <Field label="global.assistant_phone" name="assistantPhone" />
            <Field label="global.department" name="department" />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.address_information'} />
            </b>
            <Field label="global.street" name="street" />
            <Field label="global.city" name="city" />
            <Field label="global.zip_code" name="zipCode" />
            <Field label="global.state" name="state" />
            <Field label="global.region" name="region" />
            <Field label="global.country" name="country" />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.events'} />} key={'contacts.events'}>
            <EventsField contact={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.tasks'} />} key={'contacts.tasks'}>
            <TasksField contact={this.props.form.values['@id']} account={this.props.form.values.account} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.tickets'} />} key={'contacts.tickets'}>
            <TicketsField contact={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.campaigns'} />} key={'contacts.campaigns'}>
            <CampaignsField contact={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.deals'} />} key={'contacts.deals'}>
            <DealsField contact={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.orders'} />} key={'contacts.orders'}>
            <OrdersField contact={this.props.form.values['@id']} />
          </TabPane>
        )}
        {(this.props.hasInvoices && this.props.form.values['@id']) && (
          <TabPane tab={<FormattedMessage id={'global.invoices'} />} key={'contacts.invoices'}>
            <InvoicesField contact={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.quotes'} />} key={'contacts.quotes'}>
            <QuotesField contact={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.documents'} />} key={'contacts.documents'}>
            <DocumentsField contact={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={'Emails'} key={'contacts.emails'}>
            <EmailsField
              contact={this.props.form.values['@id']}
              email={this.props.form.values.email}
            />
          </TabPane>
        )}
      </SettableTabs>
    )
  }
}

const mapStateToProps = ({user}) => ({
  hasInvoices: user.features.invoices
})

export default withModalForm({
  apiResource: '/api/contacts',
  title: <FormattedMessage id={'global.edit_contact'} />,
  width: 1270,
})(connect(mapStateToProps)(ContactForm))
