import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { printName, renderDate } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.name'} />,
    dataIndex: 'name',
    sorter: true,
    width: 210,
  },
  {
    title: <FormattedMessage id={'global.status'} />,
    dataIndex: 'serviceContractStatus',
    sorter: true,
    className: 'text_center',
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.priority'} />,
    dataIndex: 'serviceContractPriority',
    sorter: true,
    className: 'text_center',
    width: 150,
    render: printName,
  },

  {
    title: <FormattedMessage id={'global.start_date'} />,
    dataIndex: 'startDate',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 140,
  },
  {
    title: <FormattedMessage id={'global.due_date'} />,
    dataIndex: 'dueDate',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const ServiceContractsField = ({ account }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Service'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.service_contract'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_service_contract'} />}
            onClick={() => {
              modals.open(MODAL_NAME.service_contract, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: { account },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/service_contracts'}
    modalKey={MODAL_NAME.service_contract}
    filters={{ account }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default ServiceContractsField
