import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import InputNumber from 'components/platform/form/inputs/InputNumber'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import Switch from 'components/platform/form/inputs/Switch'
import AsyncSelect from 'components/platform/form/inputs/AsyncSelect'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'

const { TabPane } = Tabs

class PaymentForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'payments.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.name" obb name="name" />
            <Field label="global.payment_date" obb name="paymentDate" component={Datepicker} />
            <Field label="global.income" name="income" component={Switch} />
            <Field label="global.amount" obb name="amount" component={InputNumber} />
            <Field
              label="global.status"
              name="paymentStatus"
              type={'payment_status'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.category"
              name="paymentCategory"
              type={'payment_category'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.currency"
              name="paymentCurrency"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/currencies'}
              component={AsyncSelect}
            />
            <Field
              label="global.payment_method"
              name="paymentMethod"
              type={'payment_method'}
              component={ItemCodeSelect}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/payments',
  title: <FormattedMessage id={'global.edit_payment'} />,
  width: 1200,
})(PaymentForm)
