import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { printName, renderDate } from 'components/platform/Table/renders'
import NewButton from 'components/platform/Search/NewButton'
import { FormattedMessage } from 'react-intl'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.product_image'} />,
    dataIndex: 'imagePath',
    sorter: true,
    width: 230,
  },
  {
    title: <FormattedMessage id={'global.name'} />,
    dataIndex: 'name',
    sorter: true,
    width: 200,
  },
  {
    title: <FormattedMessage id={'global.default_price'} />,
    dataIndex: 'price',
    sorter: true,
    className: 'text-center',
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.currency'} />,
    dataIndex: 'currency',
    sorter: true,
    width: 160,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.category'} />,
    dataIndex: 'productCategory',
    sorter: true,
    className: 'text-center',
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.type'} />,
    dataIndex: 'productType',
    sorter: true,
    className: 'text-center',
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.part_number'} />,
    dataIndex: 'partNumber',
    sorter: true,
    className: 'text-center',
    width: 130,
  },
  {
    title: <FormattedMessage id={'global.vendor_part_number'} />,
    dataIndex: 'vendorPartNumber',
    sorter: true,
    className: 'text-center',
    width: 170,
  },
  {
    title: <FormattedMessage id={'global.product_sheet'} />,
    dataIndex: 'productSheet',
    sorter: true,
    width: 150,
  },
  {
    title: <FormattedMessage id={'global.website'} />,
    dataIndex: 'website',
    sorter: true,
    width: 160,
  },
  {
    title: <FormattedMessage id={'global.serial_no'} />,
    dataIndex: 'serialNo',
    sorter: true,
    className: 'text-center',
    width: 170,
  },
  {
    title: <FormattedMessage id={'global.vendor_name'} />,
    dataIndex: 'vendor',
    sorter: true,
    render: printName,
    width: 210,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const ProductsField = ({ lead }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Products'}
    renderSearch={({ modals, getTable }) => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.products_services'} />
        <div className={'pull-right'}>
          <NewButton
            title={<FormattedMessage id={'global.new_product_service'} />}
            onClick={() => {
              modals.open(MODAL_NAME.product, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: { leads: [lead] },
              })
            }}
          />
        </div>
      </div>
    )}
    apiResource={'/api/products'}
    modalKey={MODAL_NAME.product}
    filters={{ leads: lead }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

export default ProductsField
