import { EditorState} from 'lexical'; // , LexicalEditor as LexEditor
import React from 'react';

import {LexicalComposer} from '@lexical/react/LexicalComposer';
// import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';

// import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";

import './lexical.scss'
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import LexicalTheme from "./LexicalTheme";
import ToolbarPlugin from "./ToolbarPlugin";



// When the editor changes, you can get notified via the
// LexicalOnChangePlugin!


// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
/* function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
} */

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
  console.error(error);
}

/*
<PlainTextPlugin
          contentEditable={<ContentEditable />}
          placeholder={<div>Enter some text...</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
* */

function Placeholder() {
  return <div className="editor-placeholder p-2">Enter some rich text...</div>;
}

export function LexicalEditor({field, form }: any) {
  if(field.value?.root){
    field.value.root.type = "root"
  }
 //  console.log(' field.value.lexical', field.value)
  const editorConfig = {
    namespace: 'MyEditor',
    // The editor theme
    theme: LexicalTheme,
    // Handling of errors during update
    onError,
    editorState: field.value ? JSON.stringify(field.value) : undefined,
    editable: false,
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      AutoLinkNode,
      LinkNode
    ],

  };

  function onChange(editorState: EditorState /* , editor: LexEditor */) {
    editorState.read(() => {
      // Read the contents of the EditorState here.
      // const root = $getRoot();

      //    const jsonState: any = $getRoot().exportJSON();
      const jsonState: any = editorState.toJSON();
      // console.log('editorState.toJSON', )
      // console.log('editor.getEditorState().toJSON()',editor.getEditorState().toJSON())

      // const newEditorState = editor.parseEditorState(jsonState);

      // set form values if changed and is not empty
      if ( jsonState?.root?.children?.[0]?.children?.length > 0) {
        form.setFieldValue(field.name, jsonState);
      }else{
        console.log(jsonState?.root, editorState)
        form.setFieldValue(field.name, null);
      }
    });
  }

  return (
    <>
      <LexicalComposer initialConfig={editorConfig}>
        <div className={`editor-container rounded-xl ${editorConfig.editable ? "shadow": ""}`}>
          {editorConfig.editable && <ToolbarPlugin />}
          <div className="prose p-2 editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />

            {/* eslint-disable-next-line react/jsx-no-bind */}
            <OnChangePlugin onChange={onChange} />
            <HistoryPlugin />
            <ListPlugin />
            <LinkPlugin />
          </div>
        </div>
      </LexicalComposer>

    </>
  );
}
// <pre>{JSON.stringify(field.value,null,2)}</pre>
