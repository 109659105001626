import React from 'react'
import update from 'immutability-helper'
import { ReactSortable } from 'react-sortablejs'

/* type SettableField = {
  visible: boolean
  label: string
} */

type settableListProps = {
  fields: any[]
  identifier?: string
  labelKey?: string
  getLabel?: (field: any) => string
  onChange?: (fields: any[]) => void
}

export const SettableList = (props: settableListProps) => {
  const {
    fields: customizedFields,
    identifier = 'key',
    labelKey = 'labelKey',
    getLabel,
    onChange
    // renders,
    // renderLabelProp,
  } = props
  /* const [fields, setFields] = useState(
    customizedFields.sort((a: any, b: any) => a.position - b.position),
  ) */

  const _onVisibleChange = (checked: any, index: any) => {
    const newFields = update(customizedFields, {
      [index]: { $merge: { visible: checked } },
    })
    // setFields(newFields)
    if(onChange) onChange(newFields)
  }

  const _onSortEnd = (newFields: any) => {
    // console.log(newFields)
    // setFields(newFields)
    if(onChange) onChange(newFields)
  }


  return (
    <ReactSortable
      style={{ padding: 10 }}
      list={customizedFields}
      setList={_onSortEnd}
      draggable={'.draggable-option'}
    >
      {customizedFields.map((field: any, index: any) => {
        let label = getLabel ? getLabel(field) : ( field[labelKey] || null )
        /* let labelId = field[labelKey] || null
        if (renderLabelProp) {
          labelId = renders[field[identifier]]?.props?.[renderLabelProp] || labelId
        } */
        // let label =  labelId ? <FormattedMessage id={labelId} /> : field.label
        if (!label) label = `Missing ${labelKey} on "${field[identifier]}"`



        return (
          <div
            className={field.customizable !== false ? 'py-2 px-2 mb-2 border border-gray-100 rounded draggable-option' : 'py-2 px-2 mb-2 border border-gray-100 rounded draggable-disabled'}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              lineHeight: '22px',
            }}
            key={field[identifier]}
          >
            <div>
              <input
                type="checkbox"
                disabled={field.customizable === false}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onChange={e => {
                  e.stopPropagation()
                  _onVisibleChange(e.target.checked, index)
                }}
                checked={field.visible}
                className={'mr-2'}
              />
              {label}
            </div>
            <div>
              <i className="fa-solid fa-sort" />
            </div>
          </div>
        )
      })}
    </ReactSortable>
  )
}
