/* eslint-disable react/destructuring-assignment */
/* global $ */
import React from 'react'
import ReactDOM from "react-dom";

$.widget('ui.dialog', $.ui.dialog, {
  // eslint-disable-next-line object-shorthand,func-names
  _allowInteraction: function(event) {
    // eslint-disable-next-line no-underscore-dangle
    return !!$(event.target).closest('.ant-calendar-picker-container') || this._super(event)
  },
})

export class DialogTitleBar extends React.Component {
  constructor(props) {
    super(props)
    this.modalRoot = props.getParent()
    this.el = document.createElement('span')
    const mounted = this.modalRoot != null
    if (!mounted) {
      // retry
      setTimeout(() => {
        this.modalRoot = props.getParent()
        if (this.modalRoot) this.modalRoot.appendChild(this.el)
      }, 1000)
    }
  }

  componentDidMount() {
    if (this.modalRoot) this.modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    try {
      this.modalRoot.removeChild(this.el)
    } catch (e) {
      console.log('componentWillUnmount Titlebar', e.toString())
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}


export default class Dialog extends React.Component {
  componentDidMount() {
    const { resizable = false, responsive = true } = this.props
    let width = this.props.width ? this.props.width : 600
    const wWidth = $(window).width()
    if (responsive && width > wWidth) {
      width = '98%'
    }
    $(this.div).dialog({
      modal: this.props.modal,
      title: this.props.title ? this.props.title : '',
      autoOpen: this.props.opened,
      width,
      buttons: this.props.buttons ? this.props.buttons : null,
      classes: {
        'ui-dialog': this.props.dialogClass,
      },
      position: { my: 'top', at: 'top+20' },
      // position: ['center', 10],
      resizable,
      beforeClose: () => {
        if(this.props.opened){
          if(this.props.onClose) this.props.onClose();
          console.log("try close but destroy");
          return false;
        }
        return true;
      }
    })
    $(this.div).on('dialogclose', (/* event, ui */) => {
      if (this.props.onClose) {
        this.props.onClose()
      }
    })
    // TODO: copy Dialog from performa
    // console.log('dialog creada')
  }

  componentWillUnmount() {
    $(this.div).dialog('destroy')
    // console.log('dialog destroy')
  }

  // llamado quando el conmponente cambia propriedades
  UNSAFE_componentWillReceiveProps(nextProps) {
    /* const { opened } = nextProps
    if (opened) {
      $(this.div).dialog('open')
    } else {
      $(this.div).dialog('close')
    } */
    if (nextProps.title !== this.props.title) {
      $(this.div).dialog('option', { title: nextProps.title })
    }
    if (nextProps.width !== this.props.width) {
      $(this.div).dialog('option', { width: nextProps.width })
    }
  }

  render() {
    return (
      <div
        ref={div => {
          this.div = div
        }}
      >
        {this.props.header && (
          <DialogTitleBar
            getParent={() => {
              const titleContainer = $(this.div).parent().find(".ui-dialog-title");
              titleContainer.html("");
              return titleContainer[0]
            }}
          >
            {this.props.header}
          </DialogTitleBar>
        )}
        {this.props.children}
        {this.props.footer && <div className='dialog-footer'>{this.props.footer}</div>}
      </div>
    )
  }
}
