import React from 'react'
import { Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import apiClient from 'services/axios'
import isArray from 'lodash/isArray'
import { FormattedMessage } from 'react-intl'

const { Dragger } = Upload

const customRequest = async (options) => {
  const { onSuccess, onError, file, onProgress, data, filename, action } = options
  // console.log('options',options)
  const fmData = new FormData()
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: (event) => {
      /* const percent = Math.floor((event.loaded / event.total) * 100);
      setProgress(percent);
      if (percent === 100) {
        setTimeout(() => setProgress(0), 1000);
      } */
      onProgress({ percent: (event.loaded / event.total) * 100 })
    },
  }
  fmData.append(filename, file)

  Object.keys(data).forEach((key) => {
    if (data[key] !== undefined) {
      if (isArray(data[key])) {
        data[key].forEach((i) => {
          fmData.append(`${key}[]`, i)
        })
      } else {
        fmData.append(key, data[key])
      }
    }
  })

  try {
    const res = await apiClient.post(action, fmData, config)

    onSuccess(res.data)
  } catch (err) {
    // console.log("Eroor: ", err);
    // const error = new Error("Some error");
    onError({ err })
  }
}

export default class DragUploader extends React.Component {
  render() {
    const { field, onSuccess, params } = this.props

    const propsUploader = {
      name: field.name,
      action: `api/documents`,
      data: params,
      headers: {
        // Authorization: 'authorization-text',
        'X-Requested-With': null,
      },
      customRequest,
      onChange(info) {
        // console.log('info',info)
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList)
        }
        if (info.file.status === 'done') {
          onSuccess(info.file.response)
          /* if (info.file.response.error) {
            message.error(info.file.response.message)
          } else {
            message.success(info.file.response.message)
            form.setFieldValue(field.name, info.file.response.url)
          } */
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`)
        }
      },
    }
    return (
      <Dragger {...propsUploader}>
        <div style={{ position: 'relative', minHeight: 500 }}>
          <UploadOutlined />
          <p>
            <FormattedMessage id={'global.drag_files'} />
          </p>
          <button
            type="button"
            className="btn btn-light btn-sm"
            style={{ marginTop: 5, display: 'block', position: 'absolute', bottom: 2, right: 2 }}
          >
            <FormattedMessage id={'global.select_file'} />
          </button>
        </div>
      </Dragger>
    )
  }
}
