import * as React from 'react'
import {Tooltip, Button} from "antd";
import { PlusOutlined } from '@ant-design/icons';

const NewButton = (props) => {
  const {onClick,title='Nuovo', disabled} = props
  return (
    <div
      style={{
        marginRight: 5,
        fontSize: 18,
        paddingTop: 0,
        paddingBottom: 0,
        lineHeight: 1.7,
        display: 'inline-block'
      }}
    >
      <Tooltip title={title}>
        <Button
          type={"primary"}
          disabled={disabled}
          onClick={onClick}
        >
          <PlusOutlined />
        </Button>
      </Tooltip>
    </div>
  )
}

export default NewButton
