import axios from 'axios'
import store from 'store'
import * as qs from "qs";
import keycloak from "config/keycloak";

// 'https://jime74m7ie.execute-api.eu-central-1.amazonaws.com'
export const BASE_URL = process.env.REACT_APP_JIRA_URL || 'http://127.0.0.1:4000'

export const jiraClient = axios.create({
  // baseURL: 'https://jime74m7ie.execute-api.eu-central-1.amazonaws.com/',
  baseURL: BASE_URL ,
  // timeout: 1000,
  // headers: { 'accept': 'application/json' }
})

jiraClient.interceptors.request.use(async request => {
  let accessToken
  // keycloak refresh token
  try {
    const refreshed = await keycloak.updateToken(5)
    if(refreshed){
      accessToken = keycloak.token
      store.set('accessToken', accessToken)
    } else {
      accessToken = store.get('accessToken')
    }
  }catch (e) {
    return keycloak.init({onLoad: 'login-required', responseMode: 'query'})
  }

  if(process.env.NODE_ENV === 'development' && document.cookie.indexOf("XDEBUG_SESSION") >=0 ){
    request.params = request.params || {}
    request.params.XDEBUG_SESSION_START='PHPSTORM'
  }

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    // request.headers.AccessToken = accessToken
  }
  return request
})

jiraClient.interceptors.request.use(config => {
  config.paramsSerializer = params2 =>
    qs.stringify(params2, {
      arrayFormat: 'brackets',
      encode: false,
    })
  return config
})

