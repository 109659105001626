import React from 'react'
import { Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { Button } from 'reactstrap'

const { Dragger } = Upload

export default class FileSelector extends React.Component {
  state = {
    fileList: [],
  }

  render() {
    const {
      field,
      form,
      title = 'Trascina un documento',
      buttonLabel = 'Seleziona un file',
    } = this.props
    const { fileList } = this.state

    const props = {
      onRemove: file => {
        form.setFieldValue(field.name, undefined)
        this.setState(state => {
          const index = state.fileList.indexOf(file)
          const newFileList = state.fileList.slice()
          newFileList.splice(index, 1)
          return {
            fileList: newFileList,
          }
        })
      },
      beforeUpload: file => {
        form.setFieldValue(field.name, file)
        this.setState({
          fileList: [file], // [...state.fileList, file],
        })
        /* this.setState(state => ({
          fileList: [...state.fileList, file],
        })); */
        return false
      },
      multiple: false,
      maxCount: 1,
      fileList,
    }

    return (
      <Dragger {...props}>
        <div className={'uploader-drag'} style={{ position: 'relative', minHeight: 150 }}>
          <UploadOutlined />
          <p>{title}</p>
          <Button color={'outline-primary'} style={{ marginTop: 10, width: 200 }}>
            {buttonLabel}
          </Button>
        </div>
      </Dragger>
    )
  }
}
