import {TabsProps, Tabs as AntTabs, notification} from 'antd'
import React, {useEffect, useMemo, useState} from "react";
import {Button} from "reactstrap";
import {SettableList, Skeleton, usePreloaderContext} from "@logicpanel/ui";
import {FormattedMessage} from "react-intl";
import Dialog from "../Dialog";
import apiClient from "../../../services/axios";

interface SettableTabsProps extends TabsProps {
  definitions: any[]
  settingsName: string
}

export const SettableTabs = ({children, tabPosition, definitions, settingsName}: SettableTabsProps)=> {

  const [opened, setOpened] = useState(false)
  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState<any[]>([])
  const {platformConfigs}: any = usePreloaderContext()

  useEffect(()=> {
    const config = platformConfigs[settingsName]?.payload?.fields || []
    const configMap = config.reduce((acc: any, item: any)=> {
      acc[item.key] = item
      return acc
    }, {})
    const newSettings = definitions
      .map((item: any)=> {
        return {...item, ...(configMap[item.key] || {})}
      }).sort((a: any, b: any) => a.position - b.position)
    setSettings(newSettings)
    setLoading(false)
  },[definitions, settingsName, platformConfigs])

  const _save  = ()=> {
    const fields = settings.map((item: any, index)=> {
      return {...item, position: index}
    })
    apiClient.post(`api/platform_configs`, {identifier: settingsName, payload: {fields}}).then(()=> {
      notification.success({message: "Impostazioni salvate"})
      setOpened(false)
    })
  }

  /* console.log(finalSettings) */

  /* const finalSettings = useMemo(()=> {
    return settings.reduce((acc: any, def: any) => {
      acc[def.key] = def
      return acc
    }, {})
  },[settings])    <pre>{JSON.stringify(platformConfigs, null, 2)}</pre>

  */
  const {childrens} = useMemo(()=> { // , finalSettings: f
    const renders = {}
    React.Children.forEach(children, (child: any) => {
      if(child){
        renders[child.key] = child
      }
    })
    const finalSettings = settings.filter((def: any) => def.visible)
    //    <pre>{JSON.stringify(f, null, 2)}</pre>
    return {
      childrens: finalSettings.map((def: any) => {
        return renders[def.key]
      }),
      finalSettings
    }
  },[children, settings])

  if(loading){
    return (
      <Skeleton className="m-3" />
    )
  }
  //     <pre>{JSON.stringify(platformConfigs[settingsName], null, 2)}</pre>

  return (
    <>
      <AntTabs tabPosition={tabPosition}>
        {childrens}
      </AntTabs>
      <div className="text-right">
        <Button color="link" onClick={()=>setOpened(true)}><span className="underline text-bold">Personalizza finestra</span></Button>
      </div>
      {opened && (
        <>
          <Dialog
            opened
            modal
            width={600}
            title="Personalizza"
            onClose={()=>setOpened(false)}
            footer={(
              <>
                <Button color="primary" onClick={()=>_save()}>Salva</Button>
                <Button color="light" onClick={()=>setOpened(false)}>Annulla</Button>
              </>
            )}
          >
            <SettableList
              fields={settings}
              onChange={(newFields: any)=> {
                console.log(newFields)
                setSettings(newFields)
                // setOpened(false)
              }}
              getLabel={(field: any) => field.labelId ? <FormattedMessage id={field.labelId} /> : field.label}
            />
          </Dialog>
        </>
      )}
    </>
  )
}

SettableTabs.TabPane = AntTabs.TabPane
