import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import InputNumber from 'components/platform/form/inputs/InputNumber'
import AsyncSelect from 'components/platform/form/inputs/AsyncSelect'
import JAutocomplete from 'components/platform/form/inputs/JAutocomplete'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'

const { TabPane } = Tabs

class QuoteForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'quotes.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.name" obb name="name" />
            <Field label="global.valid_until" name="validUntil" component={Datepicker} />
            <Field label="global.amount" name="amount" component={InputNumber} />
            <Field label="global.shipping" name="shipping" />
            <Field
              label="global.stage"
              name="quoteStage"
              type={'quote_stage'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.carrier"
              name="quoteCarrier"
              type={'quote_carrier'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.contact"
              name="contact"
              useIris
              valueKey={'@id'}
              valueLabel={'fullName'}
              source={'/api/contacts'}
              component={JAutocomplete}
              getLabel={i => `${i.fullName}`}
              filter={'fullName'}
            />
            <Field
              label="global.account"
              name="account"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/accounts'}
              component={JAutocomplete}
              getLabel={i => `${i.name}`}
              filter={'name'}
            />
            <b className="sapn2 mt-2">
              <FormattedMessage id={'global.address_information'} />
            </b>
            <Field label="global.street" name="street" />
            <Field label="global.city" name="city" />
            <Field label="global.zip_code" name="zipCode" />
            <Field label="global.state" name="state" />
            <Field label="global.country" name="country" />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.tax_currency'} />
            </b>
            <Field
              label="global.tax"
              name="tax"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/taxes'}
              component={AsyncSelect}
            />
            <Field
              label="global.currency"
              name="currency"
              useIris
              valueKey={'@id'}
              valueLabel={'name'}
              source={'/api/currencies'}
              component={AsyncSelect}
            />
            <Field label="global.delivery_cost" name="deliveryCost" component={InputNumber} />
            <Field label="global.discount" name="discount" component={InputNumber} />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/quotes',
  title: <FormattedMessage id={'global.edit_quote'} />,
  width: 1200,
})(QuoteForm)
