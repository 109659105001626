import * as React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Creators } from '../../../redux/modals'

export default function withNavigator(WrappedComponent) {
  class NewComponent extends React.Component {
    constructor(props) {
      super(props)
      this.boundActionCreators = bindActionCreators(Creators, props.dispatch)
    }

    render() {
      return <WrappedComponent modals={this.boundActionCreators} {...this.props} />
    }
  }

  const selector = state => ({
    currentUser: state.user,
  })

  return connect(selector)(NewComponent)
}
