import * as React from 'react'
import { Switch as AntSwitch } from 'antd'
import fieldProps from './fieldProps'

class Switch extends React.Component {
  render() {
    const { value, onChange } = this.props
    return <AntSwitch checked={value} onChange={(checked) => onChange(checked)} />
  }
}

export default fieldProps(Switch)
