import React from 'react' // , {useEffect, useState}
import { Tabs } from 'antd'
import { withModalForm } from 'components/platform/hocs/Form'
import Field from 'components/platform/form/Field'
import Datepicker from 'components/platform/form/inputs/Datepicker'
import InputNumber from 'components/platform/form/inputs/InputNumber'
import JAutocomplete from 'components/platform/form/inputs/JAutocomplete'
import { FormattedMessage } from 'react-intl'
import ItemCodeSelect from 'components/platform/form/inputs/ItemCodeSelect'
import ContactsField from '../ContactsPage/ContactsField'
import EventsField from '../CalendarPage/EventsField'

const { TabPane } = Tabs

class DealForm extends React.Component {
  render() {
    return (
      <Tabs tabPosition="left">
        <TabPane tab={<FormattedMessage id={'global.details'} />} key={'deals.details'}>
          <div style={{ padding: 10 }} className="fg fg-2e fg-horizontal">
            <b className={'span2'}>
              <FormattedMessage id={'global.basic_information'} />
            </b>
            <Field label="global.name" obb name="name" />
            <Field label="global.amount" name="amount" component={InputNumber} />
            <Field label="global.closing_date" name="closingDate" component={Datepicker} />
            <Field label="global.probability" name="probability" component={InputNumber} />
            <Field label="global.expected_revenue" name="expectedRevenue" component={InputNumber} />
            <Field label="global.next_step" name="nextStep" />
            <Field
              label="global.stage"
              name="dealStage"
              type={'deal_stage'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.business_type"
              name="dealBusinessType"
              type={'deal_business_type'}
              component={ItemCodeSelect}
            />
            <Field
              label="global.account"
              component={JAutocomplete}
              source={'/api/accounts'}
              name="account"
              filter={'name'}
              getLabel={i => `${i.name}`}
              useIris
              valueKey={'@id'}
            />
            <b className="span2 mt-2">
              <FormattedMessage id={'global.notes'} />
            </b>
            <Field
              className="span2 mb-3"
              label={'global.notes'}
              autoSize={{ minRows: 1 }}
              component="textarea"
              name="notes"
            />
          </div>
        </TabPane>
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.events'} />} key={'deals.events'}>
            <EventsField deal={this.props.form.values['@id']} />
          </TabPane>
        )}
        {this.props.form.values['@id'] && (
          <TabPane tab={<FormattedMessage id={'global.contacts'} />} key={'deals.contacts'}>
            <ContactsField deal={this.props.form.values['@id']} />
          </TabPane>
        )}
      </Tabs>
    )
  }
}

export default withModalForm({
  apiResource: '/api/deals',
  title: <FormattedMessage id={'global.edit_deal'} />,
  width: 1200,
})(DealForm)
