import React from 'react'
import Crud from 'components/platform/Crud'
import { MODAL_NAME } from 'config/modals'
import { printName, renderDate } from 'components/platform/Table/renders'
import { FormattedMessage } from 'react-intl'
// import NewButton from 'components/platform/Search/NewButton'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: 50,
  },
  {
    title: <FormattedMessage id={'global.invoice_number'} />,
    dataIndex: 'invoiceNumber',
    sorter: true,
    className: 'text-center',
    width: 140,
  },
  {
    title: <FormattedMessage id={'global.contact'} />,
    dataIndex: 'contact',
    sorter: true,
    width: 160,
    render: d => (d ? d.fullName : ''),
  },
  {
    title: <FormattedMessage id={'global.account'} />,
    dataIndex: 'account',
    sorter: true,
    width: 200,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.order_number'} />,
    dataIndex: 'purchaseOrder',
    sorter: true,
    className: 'text-center',
    width: 140,
    render: d => (d ? d.orderNumber : ''),
  },
  {
    title: <FormattedMessage id={'global.customer_no'} />,
    dataIndex: 'customerNo',
    sorter: true,
    className: 'text-center',
    width: 140,
  },
  {
    title: <FormattedMessage id={'global.due_date'} />,
    dataIndex: 'dueDate',
    sorter: true,
    className: 'text-center',
    width: 140,
    render: renderDate,
  },
  {
    title: <FormattedMessage id={'global.invoice_date'} />,
    dataIndex: 'invoiceDate',
    sorter: true,
    className: 'text-center',
    width: 140,
    render: renderDate,
  },
  {
    title: <FormattedMessage id={'global.discount'} />,
    dataIndex: 'discount',
    sorter: true,
    className: 'text-center',
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.paid'} />,
    dataIndex: 'paid',
    sorter: true,
    className: 'text-center',
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.status'} />,
    dataIndex: 'invoiceStatus',
    sorter: true,
    width: 140,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.currency'} />,
    dataIndex: 'currency',
    sorter: true,
    width: 150,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.tax'} />,
    dataIndex: 'tax',
    sorter: true,
    className: 'text-center',
    width: 80,
    render: printName,
  },
  {
    title: <FormattedMessage id={'global.created_at'} />,
    dataIndex: 'createdAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 110,
  },
  {
    title: <FormattedMessage id={'global.updated_at'} />,
    dataIndex: 'updatedAt',
    sorter: true,
    className: 'text-center',
    render: renderDate,
    width: 130,
  },
]

const InvoicesField = ({ contact, account }) => (
  <Crud
    pageLayout={'inline'}
    pageTitle={'Admin Invoice'}
    renderSearch={() => (
      <div style={{ fontSize: 20, padding: '10px 0' }} className={'clearfix'}>
        <FormattedMessage id={'global.invoices'} />
      </div>
    )}
    apiResource={'/api/invoices'}
    modalKey={MODAL_NAME.invoice}
    filters={{ contact, account }}
    searchValues={{ filter: '' }}
    tableSorter={{ field: 'id', order: 'desc' }}
    tableColumns={columns}
  />
)

/*
 <div className={'pull-right'}>
          <NewButton
            title={'New Invoice'}
            onClick={() => {
              modals.open(MODAL_NAME.invoice, {
                itemId: 0,
                onClose: () => {
                  setTimeout(() => getTable().fetch(), 400)
                },
                // onRemoteSaved: (apiResponse) => form.setFieldValue(field.name,[...(field.value || []), apiResponse.data['@id']]),
                defaultItem: { contact, account },
              })
            }}
          />
        </div>
*/

export default InvoicesField
