import React from 'react'
import UserMenu from './UserMenu'
import style from './style.module.scss'
/* import LanguageSwitcher from "./LanguageSwitcher"
import Actions from "./Actions";

 <div className="mr-4 d-none d-sm-block">
        <Actions />
      </div>
 <div className="mr-4 d-none d-sm-block">
        <LanguageSwitcher />
      </div>
* */
const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div id={'headerbar-root'} className={`${style.topbarLeft} mr-auto`} />
      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}

export default TopBar
